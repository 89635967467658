import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getNotificationsAPI,fetchMoreAPI,markNotificationsAsReadAPI } from "../../API";
import InfiniteScroll from "react-infinite-scroll-component";
import BackIcon from "../../assets/SVG/BackIcon";
import BookmarkIcon from "../../assets/SVG/BookmarkIcon";
import UserIcon from "../../assets/SVG/UserIcon";
import RegaloIcon from "../../assets/SVG/RegaloIcon";
import BadgdeIcon from "../../assets/SVG/BadgeIcon"
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";


export default function Notifications() {
  let navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [next, setNext] = useState();


  function parsearTexto(texto) {
    // Expresión regular para extraer la fecha y el usuario
    const regex = /(.+) le ha gustado tu publicación (\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})\.\d{6}\+00:00 by (.+)/;
    const match = texto.match(regex);
  
    if (match) {
      // Extraer el usuario, la fecha y el usuario que le gustó la publicación
      const usuario = match[1];
      const fecha_str = match[2];
      const usuario_gustado = match[3];
      
      // Convertir la fecha a objeto Date
      const fecha = new Date(fecha_str);
  
      // Calcular la diferencia de tiempo con respecto a la hora actual
      const diferencia_tiempo_ms = Date.now() - fecha.getTime();
  
      // Determinar cómo mostrar la diferencia de tiempo
      let formato_tiempo;
      if (diferencia_tiempo_ms > 7 * 24 * 60 * 60 * 1000) {
        // Más de una semana
        formato_tiempo = fecha.toLocaleDateString('es-ES', { day: 'numeric', month: 'long' });
      } else if (diferencia_tiempo_ms > 24 * 60 * 60 * 1000) {
        // Más de un día
        formato_tiempo = Math.floor(diferencia_tiempo_ms / (24 * 60 * 60 * 1000)) + ' días';
      } else {
        // Menos de un día
        formato_tiempo = Math.floor(diferencia_tiempo_ms / (60 * 60 * 1000)) + ' horas';
      }
  
      // Crear el objeto con el formato especificado
      return {
        fecha: formato_tiempo,
        tipo: 'Tu publicacion fue Wishlisted',  // Se puede ajustar según el contexto
        mensaje: `${usuario_gustado} le ha gustado tu publicación`,
        icon : <BookmarkIcon height="20px" width="20px" />
      };
    } else {
      return null;
    }
  }
  
  

  useEffect(() => {
    getNotificationsAPI().then((res) => {
      setNotifications(res.results);
      setNext(res.next);
      console.log(res.results);
      markNotificationsAsReadAPI();
    }
    );
  }, []);


  const fetchMore = () => {
    fetchMoreAPI(next).then((res) => {
      console.log(res);
      setNotifications((notifications) => {
        if (res?.results) {
          return [...notifications, ...res.results];
        } else {
          return notifications;
        }
      });
      setNext(res.next);
    });
  };

  return (

    <div className='ProfileLayout' style={{}}>
        <ScrollToTopOnMount />
        <div className="HeaderSpacer" style={{ height: "56px", width: "30px", }} />
        <div className='ProfileBox'>
            <div className='ProfileHeaderBar'>
                <button className='FullPostHeaderButton'  onClick={() => navigate(-1)}>
                    <BackIcon  />
                </button>
                <div className="ProfileEditTitle"> Mis Notificaciones </div>
                <button className='FullPostHeaderButton'>
                    <div style={{width:"20px", height:"24px"}} />
                </button>
            </div>
              <InfiniteScroll
                dataLength={notifications ? notifications.length : 5}
                next={fetchMore}
                hasMore={true}
                //loader={<h4>Loading...</h4>}
              >
              {notifications.length>0  && 
                notifications.map((notification) => {
                const message = notification.message
                const type = notification.content_type.model
                let parsedNotification; 
                if(type =="post"){
                  parsedNotification =parsearTexto(message)
                }
                else if (type == "benefit"){
                  parsedNotification = {
                    fecha: null,
                    tipo: "Beneficio",  // Se puede ajustar según el contexto
                    mensaje: "¡Tienes un nuevo beneficio!",
                    icon : <RegaloIcon color="#696969" height="20px" width="20px" />
                  }
                }
                else if (type == "user" ){
                  parsedNotification = {
                    fecha: null,
                    tipo: "follow",  // Se puede ajustar según el contexto
                    mensaje: message,
                    icon : <UserIcon height="20px" width="20px" />
                  }
                }
                return (
                  <button className="NotificationButton">
                  <div className="NotificationBox"> 
                  {!notification.is_read && 
                    <div className="NotificationBadge">
                      <BadgdeIcon />
                    </div>
                  }
                  <div className="NotificationImage">
                    {parsedNotification?.icon}
                  </div>
                  <div className="NotificationInfo">
                    <div className="NotificationInfoTop">
                        <div className="NotificationTitle">
                          {parsedNotification?.tipo}
                        </div>
                        <div className="NotificationDate">
                            {parsedNotification?.fecha}
                        </div>
                    </div>
                    <div className="NotificationInfoBottom">
                          {parsedNotification?.mensaje}
                    </div>
                  </div>
                  </div>
                  <div className="NotificationBadge"/>
                  </button>
                );
              })}
              
               </InfiniteScroll> 
        </div>  
</div>
  )};
   