import React, { useState, useEffect } from "react";
import {
  ProductAPI,
  isWishlistProductAPI,
  addWishlistProductAPI,
  deleteWishlistProductAPI,
} from "../../API";

// Icons
import BookmarkIcon from "../../assets/SVG/BookmarkIcon";
import ShareModal from "../Components/ShareModal";

export default function ProductModal(props) {
  const { handleCloseModal, productRec,productID } = props;
  console.log(productRec)
  const [isWish, setIsWish] = useState(false);
  const [product, setProduct] = useState(null);

  let  image_size = window.innerWidth - 110;
  if (image_size > 350) {
    image_size = 350;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (productID) {
            ProductAPI(productID).then(res => {setProduct(res);console.log(res)})

            isWishlistProductAPI(productID).then(res => setIsWish(res.status))
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (productID) {
      fetchData();
    }
  }, [productID]);

  const handlePressWish = async () => {
    try {
      if (!isWish) {
        await addWishlistProductAPI(productID);
        setIsWish(true);
      } else {
        await deleteWishlistProductAPI(productID);
        setIsWish(false);
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
    }
  };





  const HtmlToReactParser = require("html-to-react").Parser;
  let htmlInput = product?.description;
  let htmlToReactParser = new HtmlToReactParser();
  let reactElement = htmlToReactParser.parse(htmlInput);


  return (
    <div className="ProductModal" onClick={() =>  handleCloseModal()}>
      <div className="ProductModalContent">
        <div className="ProductModalRectangle" />
        <div className="ProductBox">
          <div
            className="ProductPicture"
            style={{ backgroundImage: `url(${product?.content_file})`,
            width : image_size  + "px", height:image_size + "px"
          }}
          />
          <div className="ProductInfo">
            <div className="ProductName">
              {product?.name}
            </div>
          </div>
          <div className="Divider" />

       

        </div>
        <div className="ProductBar">
          <div className="ProductBarLeft">
            <ShareModal  specificLink={window.location.href.split('/').slice(0, 2).join('/') + "/product/"+productID} id={productID} contents={[product?.content_file]} shareBody={"Mira este producto en Cranberry Chic : "+ product?.name} />
            <button className="FullPostHeaderButton" onClick={handlePressWish}>
              <BookmarkIcon
                height="20px"
                width="20px"
                color={isWish ? "#B33553" : ""}
                fill={isWish ? "#EBC7D0" : ""}
              />
            </button>
          </div>
          <div className="ProductBarRight">
            <button className="ProductBarButton" onClick={handleCloseModal}>
              Cerrar
            </button>
            <button
              className="ProductBarButton ButtonFilled"
              onClick={() => window.open(productRec?.link, "_blank")}
            >
              Comprar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
