import React, { useState, useEffect,useRef } from "react";
import Carousel from "react-simply-carousel";

export default function SliderMenu(props) {
  const setAlbumFilter = props.setAlbumFilter;
  const [activeSlide, setActiveSlide] = useState(props.albumFilter);
  const [position, setPosition] = useState(0)
  const fixed = props.fixed;

  var options = [
    { label: "todo", value: "0" },
    { label: "look", value: "1" },
    {label: "trends", value: "3" }, //solo sube admins
    { label: "travel", value: "2" },
    { label: "lifestyle", value: "4" },
  ];

  if (fixed) {
    // Borra el objeto con el label "Trends" de options
    options = options.filter(option => option.val !== "3");
  }

  useEffect(() => {
    setAlbumFilter(options[activeSlide].value);
  }, [activeSlide]);


  useEffect(() => {
    // Función para manejar el tamaño de la ventana y ajustar la posición de los elementos Fade
    function handleResize() {
      if (window.innerWidth <= 480) {
        // Si la ventana es menor o igual a 480px, establecer la posición en 0
        setPosition(0);
      } else {
        // Si la ventana es mayor que 480px, calcular el desplazamiento
        const displacement = (window.innerWidth - 480) / 2;
        setPosition(displacement);
      }
    }

    // Llamar a handleResize inicialmente para configurar la posición inicial
    handleResize();

    // Agregar un listener para el evento de cambio de tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Eliminar el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className={fixed ? "SliderMenuBackground" : "SliderMenuBackgroundNotFixed"} >
      <center>
          <Carousel
            containerProps={{
              className: "SliderMenuCarousel",
            }}
            activeSlideIndex={activeSlide}
            activeSlideProps={{
              className: "ActiveSlide",
            }}
            onRequestChange={setActiveSlide}
            forwardBtnProps={{
              children: "",
              style: {
                width: 0,
                height: 0,
                minWidth: 0,
                alignSelf: "center",
                border: "none",
                background: "none",
              },
            }}
            backwardBtnProps={{
              children: " ",
              style: {
                width: 0,
                height: 0,
                minWidth: 0,
                alignSelf: "center",
                border: "none",
                background: "none",
              },
            }}
            dotsNav={{
              show: false,
              itemBtnProps: {
                style: {
                  height: 0,
                  width: 0,
                },
              },
              activeItemBtnProps: {
                style: {
                  height: 0,
                  width: 0,
                },
              },
            }}
            itemsToShow={4}
            centerMode={true}
          >
            {options.map((option, i) => (
              <p
                key={i}
                className={activeSlide === i ? "SliderMenuItem ActiveSlide" : "SliderMenuItem"}
                onClick={() => setActiveSlide(i)}
              >
                {option.label.toUpperCase()}
              </p>
            ))}
          </Carousel>
          </center>
          <div className={fixed ?  "SliderRectangle"  : "SliderRectangleNotFixed"} />
          <div className={fixed ?  "Fade LeftFade"   : ""} style={fixed ? {left : position } : {}}/>
          <div className={fixed ?  "Fade RightFade"  : ""} style={fixed ? {right : position } : {}}/>
        </div>
  );
}
