// All function that consumes API
import { toUnitless } from "@mui/material/styles/cssUtils";
import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({ baseURL: API_URL });
const ERROR_MESSAGE = "Ocurrió un error con el servidor, intentar nuevamente";
const USERNOTFOUND = "Usuario y/o contraseña incorrectos";
const CLOSEDSESSION = "Sesión cerrada, debe volver a ingresar";

const options = [
  { label: "look", value: "1" },
  { label: "travel", value: "2" },
  //{ label: "Tendencia", value: "3" }, //solo sube admins
  { label: "lifestyle", value: "4" },
];

export const setAuthToken = (token) => {
  if (token) {
    instance.interceptors.request.use(function (config) {
      config.headers.Authorization = "Bearer " + token;
      return config;
    });
  } else delete axios.defaults.headers.common["Authorization"];
};

export async function refreshToken(refresh_token) {
  try {
    const response = await instance.post("token/refresh/", {
      refresh: refresh_token,
    });
    if (response.status === 200) {
      const token = response.data.access;
      setAuthToken(token);
      localStorage.setItem("token", token);
      return true;
    } else {
      await LogoutAPI();
      alert(CLOSEDSESSION);
      window.location.href = "/login";
    }
  } catch (error) {
    const message = error.message;
    //console.log(message);
    if (message.includes("401")) {
      alert(USERNOTFOUND);
    } else {
      alert(ERROR_MESSAGE);
    }
    return false;
  }
}
//Iniciar Sesión
export async function LoginAPI(email, password) {
  //console.log(email, password);
  const data = { email: email, password: password };
  try {
    const response = await instance.post("token/", data);
    if (response.status === 200) {
      const token = response.data.access;
      const refresh = response.data.refresh;
      localStorage.setItem("token", token);
      localStorage.setItem("refresh", refresh);
      //set token to axios common header
      setAuthToken(token);
      return {status : true, message :  ""};
    }
  } catch (error) {
    const message = error.message;
    if (message.includes("401")){
      return {status : false, message: "Usuario y/o contraseña incorrecta" };
    }
    else{
      return {status : false, message: "Ocurrió un error en el servidor, porfavor intentar nuevamente" };
    }
  }
}

//Iniciar Sesión
export async function ActivateAPI(email, activationCode,password) {
  const data = { email: email, code: activationCode,password:password  };
  try {
    const response = await instance.post("users/activate/", data);
    if (response.status === 200) {
      //set token to axios common header
      setAuthToken(token);
      const token = response.data.access;
      const refresh = response.data.refresh;
      localStorage.setItem("token", token);
      localStorage.setItem("refresh", refresh);
      return {status : true, message :  ""};
    }
  } catch (error) {
    const message = error.message;
    return {status : false, message: "Ocurrio un problema, por favor revisa en tu email el codigo y usuario asignados" };
  }
}


export async function ResetPassword(email, activationCode) {
  const data = { email: email, activationCode: activationCode };
  try {
    const response = await instance.post("activate/", data);
    if (response.status === 200) {
      //redirect user to home page
      window.location.href = "/login";
      return true;
    }
  } catch (error) {
    const message = error.message;
    //console.log(message);
    if (message.includes("401")) {
      alert(USERNOTFOUND);
    } else {
      alert(ERROR_MESSAGE);
    }
    return false;
  }
}


//Cerrar Sesión
export async function LogoutAPI() {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh");
  localStorage.removeItem("me");
}

//Cargar feed
export async function MyFeedAPI(filter, tags) {
  try {
    const tagQueryString = tags.map(tagItem => `categories=${tagItem}`).join('&');

    const url = `${API_URL}posts/feed/${filter || tags.length ? '?' : ''}` +
            `${filter && filter!== "0" ? 'albums=' + filter + " " : ''}` +
            `${filter && filter!== "0" && tags.length > 0 ? '&' : ''}` +
            `${tagQueryString}`;

    const response = await axios.get(url);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    const message = error.message;
    return [];
  }
}

export async function fetchMoreAPI(link, need_auth) {
  try {
    if (need_auth) {
      var config = {
        method: "get",
        url: link,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      const response = await axios(config);
      //console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } else {
      const response = await axios.get(link);
      //console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    }
  } catch (error) {
    return [];
  }
}

//Sigue o no  a un usuario
export async function isFollowingAPI(id) {
  var config = {
    method: "get",
    url: API_URL + "users/" + id + "/follow/",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  try {
    const response = await axios(config);
    //console.log(response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return null;
  }
}

//Seguir a un usuarios
export async function followAPI(id) {
  var config = {
    method: "post",
    url: API_URL + "users/" + id + "/follow/",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  try {
    const response = await axios(config);
    //console.log(response);
    if (response.status === 200 && response.data.status === "New following") {
      return true;
    }
  } catch (error) {
    return null;
  }
}

//Dejar de seguir a un usuario
export async function unfollowAPI(id) {
  var config = {
    method: "delete",
    url: API_URL + "users/" + id + "/follow/",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  try {
    const response = await axios(config);
    //console.log(response);
    if (
      response.status === 200 &&
      response.data.status === "Unfollow succesful"
    ) {
      return false;
    }
  } catch (error) {
    return null;
  }
}

//Get my wishlist
export async function myWishlistAPI(filter, tag) {
  try {
    let url = API_URL + "posts/wishlist/";
    if (filter && filter.value != 0) {
      url = url + "?albums=" + filter.value;
    }
    if (tag) {
      url = url + "&categories=" + tag;
    }
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    //console.log(response.data);
    return response.data;
  } catch (error) {
    const message = error.message;
    //console.log(message);
  }
}



export async function myWishlistProductAPI() {
  try {
    let url = API_URL + "products/wishlist/";
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    //console.log(response.data);
    return response.data;
  } catch (error) {
    const message = error.message;
    //console.log(message);
  }
}




//Add post to  my wishlist
export async function addWishlistAPI(postid) {
  var config = {
    method: "post",
    url: API_URL + "posts/" + postid + "/wish/",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  try {
    const response = await axios(config);
    //console.log(response);
    if (response.status === 200 && response.data.status === "Added to wishlist") {
      return true;
    }
  } catch (error) {
    return null;
  }
}


export async function addWishlistProductAPI(productID) {
  var config = {
    method: "post",
    url: API_URL + "products/" + productID + "/wish/",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  try {
    const response = await axios(config);
    //console.log(response);
    if (response.status === 200 && response.data.status === "Added to wishlist") {
      return true;
    }
  } catch (error) {
    return null;
  }
}

// Delete from wishlist
export async function deleteWishlistAPI(postid) {
  var config = {
    method: "delete",
    url: API_URL + "posts/" + postid + "/wish/",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  try {
    const response = await axios(config);
    //console.log(response);
    if (response.status === 200 && response.data.status === "Unwish succesful") {
      return false;
    }
  } catch (error) {
    return null;
  }
}


export async function deleteWishlistProductAPI(productID) {
  var config = {
    method: "delete",
    url: API_URL + "products/" + productID + "/wish/",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  try {
    const response = await axios(config);
    //console.log(response);
    if (response.status === 200 && response.data.status === "Unwish succesful") {
      return false;
    }
  } catch (error) {
    return null;
  }
}


//Check if  post is in my wishlist
export async function isWishlistAPI(postid) {
  var config = {
    method: "get",
    url: API_URL + "posts/" + postid + "/wish/",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  try {
    const response = await axios(config);
    //console.log(response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return null;
  }
}


//Check if  post is in my wishlist
export async function isWishlistProductAPI(productID) {
  var config = {
    method: "get",
    url: API_URL + "products/" + productID + "/wish/",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  try {
    const response = await axios(config);
    //console.log(response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return null;
  }
}

//Get Profile
export async function MeAPI() {
    const token = localStorage.getItem("token");
    if(token){
      try {
        const response = await instance.get("users/me/", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      localStorage.setItem("me", JSON.stringify(response.data));
      return true
      } catch (error) {
        const message = error.message;
        console.log("log in not_valid")
        const response = await refreshToken(localStorage.getItem("refresh"))
        return false;
      }
    }
    return false 
}



export async function ProfileAPI(username) {
  try {
    const response = await instance.get("users/@"+username +"/", {
      
    });
    return response.data;
  } catch (error) {
    const message = error.message;
    return null;
  }
}






function parseAddress(addressString) {
  // Crear un elemento div temporal para poder manipular el HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = addressString;

  // Buscar los elementos span con las clases específicas
  const streetAddressElement = tempDiv.querySelector('.street-address');
  const localityElement = tempDiv.querySelector('.locality');
  const regionElement = tempDiv.querySelector('.region');
  const postalCodeElement = tempDiv.querySelector('.postal-code');
  const countryNameElement = tempDiv.querySelector('.country-name');

  // Construir un objeto con las propiedades de la dirección
  const addressObject = {
    streetAddress: streetAddressElement ? streetAddressElement.textContent.trim() : '',
    locality: localityElement ? localityElement.textContent.trim() : '',
    region: regionElement ? regionElement.textContent.trim() : '',
    country: countryNameElement ? countryNameElement.textContent.trim() : ''
  };

  return addressObject;
}


export async function CreatePostAPI(
  files,
  categories,
  albums,
  location,
  description,
  rate,
  isGlobal,
  ratioSquare
) {
  const me =  JSON.parse(localStorage.getItem("me"));
  const ratio = ratioSquare ?  "1:1" : "4:3" ;
  var FormData = require("form-data");
  var data = new FormData();
  categories.map((cat, i) => data.append("categories[" + i + "]", cat));
  albums.map((album, index) =>
    data.append("albums[" + index + "]", albums[index].value)
  );
  for (let j = 0; j < files.length; j++) {
    const file = files[j];
    data.append("contents[" + j + "]content_file", file, file.name);
    data.append("contents[" + j + "]content_type", file.type);
    data.append("contents[" + j + "]content_ratio", ratio)
    //Establecer cuando es según orden
    // data.append("contents[" + j + "]priority", 0);
  }
  data.append("user", me.id);
  data.append("description", description);
  if (rate) {
    data.append("grade", rate);
  }
  if (location){
    
    const address = parseAddress( location.adr_address);
    console.log(address)
    data.append("place.streetAddress",address.streetAddress)
    data.append("place.city",address.locality)
    data.append("place.region",address.region)
    data.append("place.country",address.country)
    data.append("place.google_place_id",location.place_id)
    data.append("place.name",location.name)
    data.append("place.url",location.url)

  }
  data.append("is_global", isGlobal);

  var config = {
    method: "post",
    url: API_URL + "posts/new/",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };
  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    const message = error.message;
    //console.log(message);
  }
}

export async function CreateGarmentAPI(content_id, garment) {
  const token = localStorage.getItem("token");
 //console.log(garment);
  var data = {
    post_content: content_id,
    colors: garment.colors,
    garment_type: garment.type,
    x_position: parseInt(garment.position[0]),
    y_position: parseInt(garment.position[1]),
  };
 //console.log(data);
  var config = {
    method: "post",
    url: "https://canneberge.cranberrychic.com/api/garments/",
    headers: {
      Authorization: "Bearer " + token,
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      //console.log(JSON.stringify(response.data));
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);
    });
}

export async function CreatePlaceAPI(location) {
  var FormData = require("form-data");
  var data = new FormData();
  data.append("name", location.name);
  data.append("google_place_id", location.place_id);
  data.append("url", location.url);
  var config = {
    method: "post",
    url: API_URL + "places/",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: data,
  };
  try {
    const res = await axios(config);
    if (res.status === 201) {
      return res.data.id;
    }
  } catch (error) {
   //console.log(error);
    if (error.response.status === 400) {
     //console.log("ya existe");
      config = {
        method: "get",
        url: API_URL + "places/?google_place_id=" + location.place_id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      };
      const res2 = await axios(config);
      return res2.data.results[0].id;
    }
  }
}

export async function GetPlaceAPI(place_id) {
  try {
    //console.log(query);
    const response = await axios.get(API_URL + "places/" + place_id, {
      headers: {
        
      },
    });
    return response.data;
  } catch (error) {
    const message = error.message;
    //console.log(message);
  }
}

export async function SearchAllAPI(query) {
  try {
    //console.log(query);
    const response = await instance.get("search/?name=" + query, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {
    const message = error.message;
    //console.log(message);
  }
}

export async function PostAPI(id) {
  try {
    const response = await axios.get(API_URL + "posts/" + id + "/");
    return response.data;
  } catch (error) {
    const message = error.message;
  }
}


export async function DeletePostAPI(id) {
  try {
    const response = await axios.delete(API_URL + "posts/" + id + "/");
    return response.data;
  } catch (error) {
    const message = error.message;
  }
}



export async function AlbumAPI(userid, album) {
  try {
    if (album){
      const response = await instance.get(
        "/posts/?user=" + userid + "&albums=" + album,
        {
          headers: {
          },
        }
      );
      return response.data;
    }
    else {
      const response = await instance.get(
        "/posts/?user=" + userid ,
        {
          headers: {
         },
        }
      );
      return response.data;
    }
  } catch (error) {
    const message = error.message;
  }
}

export async function updateProfileAPI(
  deletedImage,
  image,
  profileDescription,
  tiktokUser,
  igUser,
) {
  var FormData = require("form-data");
  var data = new FormData();
  if(deletedImage){
    data.append("profile_picture", new File([], ''), "");
  }
  if(image && !deletedImage){
    data.append("profile_picture", image, image.filename);
  }
  if(tiktokUser){
    data.append("tiktok_user", tiktokUser);
  }
  if(igUser){
    data.append("ig_user", igUser)
  }
  data.append("profile_description", profileDescription);

  var config = {
    method: "patch",
    url: API_URL + "users/me/",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      return [true,"Perfil Actualizado"]
    })
    .catch(function (error) {
     console.log(error);
     return [false,error]
    });
}

export async function LatestPostMagApi() {
  var config = {
    method: "get",
    url: "https://magazine.cranberrychic.com/wp-json/wp/v2/posts?_fields=title,jetpack_shortlink,jetpack_featured_media_url",
  };
  try {
    const response = await axios(config);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    const message = error.message;
    return [];
  }
}


export async function peekNotificacionsAPI(){
  try {
    const token = localStorage.getItem("token");
    const response = await instance.get("/notifications/peek/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  return response.data;
} catch (error) {
  const message = error.message;
}

}
//Check notifications
export async function getNotificationsAPI() {
    try {
      const token = localStorage.getItem("token");
      const response = await instance.get("/notifications/", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    return response.data;
  } catch (error) {
    const message = error.message;
  }
}

export async function markNotificationsAsReadAPI() {
    var config = {
      method: "POST",
      url: API_URL + "notifications/read_all/",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: {}
    };
  
    axios(config)
      .then(function (response) {
        return(response)
      })
      .catch(function (error) {
       //console.log(error);
      });
}





export async function myBenefitsAPI() {
  try {
    let url = API_URL + "assignations/";
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    //console.log(response.data);
    return response.data;
  } catch (error) {
    const message = error.message;
    //console.log(message);
  }
}


export async function BenefitAPI(id) {
  try {
    let url = API_URL + "assignations/"+id+"/";
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    //console.log(response.data);
    return response.data;
  } catch (error) {
    const message = error.message;
    //console.log(message);
  }
}


export async function BenefitAcceptAPI(
  boolean, id
  ){
  var FormData = require("form-data");
  var data = new FormData();
  if (boolean){
    data.append("state",1)
  }
  else {
    data.append("state",2)
  }
  var config = {
    method: "patch",
    url: API_URL + "assignations/"+ id + "/",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
     //console.log(error);
    });
}

export  async function BenefitAddGradeCommentAPI(
  comment,grade,id
  ){
  var FormData = require("form-data");
  var data = new FormData();
  data.append('user_comment', comment);
  data.append('user_grade ', grade);
  var config = {
    method: "PATCH",
    url: API_URL + "assignations/"+ id + "/",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
     //console.log(error);
    });
}

export async function BenefitAddRecieptAPI(
  file,id
  ){
  var FormData = require("form-data");
  var data = new FormData();
  data.append('receipt_file',file,file.name);
  var config = {
    method: "POST",
    url: API_URL + "assignations/"+ id + "/add_receipt/",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
    });
}



export async function NotifyMeAPI(
  email,post_id
  ){
  var FormData = require("form-data");
  var data = new FormData();
  data.append('email',email);
  data.append('post', post_id);
  var config = {
    method: "POST",
    url: API_URL + "notifyme/",
    headers: {
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
    });
}



export async function ProductAPI(id) {
  try {
    const response = await axios.get(API_URL + "products/" + id + "/");
    return response.data;
  } catch (error) {
    const message = error.message;
  }
}


export async function ProductsAPI() {
  try {
    const response = await axios.get(API_URL + "products/");
    return response.data;
  } catch (error) {
    const message = error.message;
  }
}



