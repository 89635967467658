import React, { useState, useRef,useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import Slider from "@mui/material/Slider";
import CloseIcon from "../../assets/SVG/CloseIcon";

export default function NewAvatar(props) {
  const setShow = props.setShow;
  const image = props.image;
  console.log(image);
  const setImage = props.setImage;
  const editor = useRef();
  const newImage = props.newImage;
  const setNewImage=props.setNewImage;
  const [valueScale, setValueScale] = React.useState(50);
  const preFoto = props.preFoto;
  const setDeletedImage = props.setDeletedImage;
  const [preDeletedImage,setPreDeletedImage] = React.useState(false)

  function handleChangeScale(event, newValue) {
    setValueScale(newValue);
  }

  const handleClick = () => {
      if (image){
        //const image = editor.current.getImageScaledToCanvas().toDataURL();
        const auxImage = editor.current.getImageScaledToCanvas();
        // Convertir la imagen del lienzo en un Blob
        auxImage.toBlob(blob => {
        // Crear un nuevo objeto File a partir del Blob
        const file = new File([blob], image.name, { type: image.type  });
        console.log("newfile" ,file);
        setNewImage(file);
        });
        if (newImage !== null) {
          setShow(false);
        }
      }
      else{
        setDeletedImage(preDeletedImage)
        setShow(false)
      }
  }


  return (
    <div className="ModalCenteredLayout" onClick={() => setShow(false)}>
        <div className="ModalCenteredBox" onClick={(e) => e.stopPropagation()}>
           <button className="ModalCloseButton" onClick={(e) => { e.preventDefault(); setShow(false) }}>
             <CloseIcon width="20px" height="20px" />
           </button>
           <div className="ModalTitle"> </div>
           <div className="ModalBody">
             {image ? (
              <div className="UploadProfilePictureBox">
                <AvatarEditor
                  width={220}
                  height={220}
                  image={image}
                  ref={editor}
                  border={30}
                  scale={valueScale / 50}
                  rotate={50 * 3.6 - 180}
                  borderRadius={1000}
                />
              </div>
            ) : (
              <div className="UploadProfilePictureBox" style={{backgroundImage : preDeletedImage ? "" : `url(${preFoto})`,}}>
                <div className="UploadButton">
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      setImage(event.target.files[0]);
                    }}
                    style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                  />
                  <label
                    className="UploadLabel"
                    htmlFor="fileInput"
                    style={{ cursor: "pointer" }}
                  >
                    Subir Imagen
                  </label>

                </div>
                <div className="UploadButton" onClick={() => setPreDeletedImage(true)}>
                  <label
                    className="UploadLabel"
                  >
                    Borrar Imagen
                  </label>
                  
                </div>
              </div>
            )}
            {image && (
              <Slider
                aria-label="Scale"
                value={valueScale}
                onChange={handleChangeScale}
              />
            )}
          </div>
          <button className="ModalButton" onClick={handleClick}>
            Actualizar Perfil
          </button>
          <button className="ModalSecondaryButton" onClick={(e) => { e.preventDefault(); setShow(false); setImage(false) }}>Volver</button>
        </div>
      </div>
  );
}
