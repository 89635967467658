import { useState, useRef, useEffect } from "react";
import React from "react";
import CranMagEmbed from "../Components/CranMagEmbed";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../assets/SVG/SearchIcon";
import ProductBox from "../Components/ProductBox";
import ProductModal from "../Components/ProductModal";
import CloseIcon from "../../assets/SVG/CloseIcon";
import { ProductsAPI } from "../../API";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";

export default function SearchBar(props) {
  const bannerSize  = window.innerWidth -48;
  const [search, setSearch] = useState("");
  const [products,setProducts] = useState(null);
  let productBox_size = Math.round((window.innerWidth - (60 *2))/2)
  if (productBox_size > 150){
    productBox_size = 150 ;
  } 

  let navigate = useNavigate();


  //TODO : Busquedas recientes
  // const [busquedasRecientes, setBusquedasRecientes] = useState([
  //   "abrigo rojo",
  //   "gabriela fuentes",
  //   "vestido cocktail",
  //   "cardigan",
  // ]);
  // const text = ["Búsquedas recientes"];

 

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      navigate('/search/'+search)
    }

  };


  
  useEffect(() => {
    const fetchData = async () => {
      try {
        ProductsAPI().then(res => {setProducts(res.results);console.log(res)})
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if(!products){
    fetchData()
    }
  }, [products]);

  return(
    <div className="SearchLayout">
      <ScrollToTopOnMount />
      <div className="HeaderSpacer" style={{height:"56px", width:"30px"}} />
        <div className="SearchBox">
          <div className="ProfileInputBox">
            <div className="PostFormMultiSelect">
                <SearchIcon width="20px" height="20px" />
                <input type="text" placeholder="Buscar Tendencias, Inspiración"  value={search}onChange={(e)=> setSearch(e.target.value)} 
                    onKeyDown={handleEnter} />
                 { search ? 
                    <button className="CloseMiniButton" onClick={(e) => {
                      e.preventDefault();
                      setSearch(""); // Borrar el contenido del campo de entrada
                    }}>
                      <CloseIcon width="20px" height="20px" />
                    </button>
                     : 
                    <div style={{height:"20px", width:"20px"}} />
                    }
            </div>
          </div>
          <div className="SearchSection">
              Descubre lo ultimo de CranberryMag
              <CranMagEmbed size={bannerSize} />
          </div>
          <div  className="Divider" />
          <div className="ProductsTrending">
            Trending en Wishlists
            <div className="ProductosFeed">
            <div className="ProductsContainer"> 
            <div className="ProductsBox">
              {products
                ? products.map((producto, index) => {
                    return (
                      <button onClick={() => navigate("../../product/"+ producto.id)}>
                      <ProductBox key={index} product={producto} i={producto.id} size={productBox_size} />
                      </button>
                    );
                  })
                : " "}
            </div>
            </div>
            </div>
            <div className="HeaderSpacer" style={{height:"70px", width:"30px"}} />
          </div>
        </div>
    </div>
  )
}


  