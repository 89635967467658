import React, { useEffect, useState } from "react";
import { LoginAPI } from "../../API";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";

//Import Icons

import UserIcon from "../../assets/SVG/UserIcon"
import ShowIcon from "../../assets/SVG/ShowIcon";
import NotShowIcon from "../../assets/SVG/NotShowIcon"; 
import LockIcon from "../../assets/SVG/LockIcon";
import CBCLogo from  "../../assets/SVG/CBCLogo";

export default function Login() {
  const invalid_email = "Formato mail inválido";
  const log_in_message = "Ingresar ";
  const forgot_password = "Olvidé mi contraseña";
  const register = " Registrate aquí";
  const no_acount = "No tienes cuenta";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showEmailMessage, setEmailMessage] = useState(null);
  const [showErrorMessage, setErrorMessage] = useState(null);
  const [passswordError,setPasswordError] = useState(null);

  const navigate = useNavigate();

  function isEmail(value) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(value)) {
      setEmailMessage(invalid_email);
    } else {
      setEmailMessage(null);
    }
  }


  useEffect(() => {
    setErrorMessage(null)
    setPasswordError(null)
  }, [email,password]);



  return (
    <div className="PrincipalBox">
      <CBCLogo width="100" height="100" color="white" />
      <Form className="LoginForm" 
            onSubmit={(e) => {
              e.preventDefault();
              if(!showEmailMessage && email && password){
                LoginAPI(email, password).then(res => {
                  console.log(res)
                  if(res.status){
                    window.location.href= "/"
                  }
                  else{
                    setErrorMessage(res.message)
                  }
                });
              }
              if(!email){
                setEmailMessage("Ingrese Email")
              }
              if(!password){
                setPasswordError("Ingrese Contraseña ")
              }
            }}>
        {(showEmailMessage || showErrorMessage || passswordError) && <span  className="ErrorText">
          {showEmailMessage}{showErrorMessage} {passswordError}
        </span>}
        <div className={showEmailMessage || showErrorMessage ?"LoginInput ErrorBorder" :"LoginInput"}>
          <UserIcon />
          <input
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value.trim().toLocaleLowerCase());
              isEmail(e.target.value.toLocaleLowerCase());
            }}
          />
        </div>

        <div className={showErrorMessage || passswordError ?"LoginInput ErrorBorder" :"LoginInput"}>
          <LockIcon />
          <input
            type={!showPassword ? "password" : "text"}
            placeholder="Contraseña"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <button
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            <ShowIcon show={!showPassword} />
          </button>
        </div>

        <button className="RecentPostButton" type="Submit">
          {log_in_message}
        </button>

        {/* <div id="TextAndLink">
          <p id="PrincipalText">{no_acount}? </p>
          <button className="LinkButton" onClick={() => navigate("/register")}>
            {register}
          </button>
        </div>
        <div id="TextAndLink">
          <button
            className="LinkButton"
            onClick={() => navigate("/resetpassword")}
          >
            {forgot_password}
          </button>
        </div>
      </div>
      <div id="TermsConditionsText">
        <p id="PrincipalTextSmall">
          Al ingresar,aceptas nuestros{" "}
          <button id="LinkButtonVariant">Términos y Condiciones</button> y
          confirmas haber leído nuestra{" "}
          <button id="LinkButtonVariant">Política de privacidad</button>
        </p>
      </div> */}
    </Form>
    </div>
  );
}

