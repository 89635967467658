import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { updateProfileAPI } from "../../API";
import CloseIcon from "../../assets/SVG/CloseIcon";
import NewAvatar from "../Components/AvatarEditor";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";


//Icons
import BackIcon from "../../assets/SVG/BackIcon";
import { InstagramIcon, TiktokIcon } from "../../assets/SVG/SocialIcons";
import DiamondIcon from "../../assets/SVG/DiamondIcon";

export default function EditProfile() {
  const [data, setData] = useState(null);
  const [profileDescription, setProfileDescription] = useState();
  const [igUser, setIgUser] = useState();
  const [tiktokUser, setTiktokUser] = useState();
  const [image, setImage] = useState(null);
  const [show, setShow] = useState();
  const navigate = useNavigate();
  const [newImage,setNewImage]  = React.useState(null);
  const [message,setMessage] = React.useState();
  const [error,setError] = React.useState();
  const [deletedImage, setDeletedImage] = React.useState(false);

  useEffect(() => {
    const me = JSON.parse(localStorage.getItem("me"));
      if (me) {
        setData(me);
        setProfileDescription(me.profile_description);
        setIgUser(me.ig_user);
        setTiktokUser(me.tiktok_user);
      }
  }, []);

  return (
    <div className='ProfileLayout' >
      <ScrollToTopOnMount />
      {message  && 
              <button className={`SnackBar ${message ? 'show' : ''}`} style={{ top: "70px" }} onClick={() => setMessage(null)}>
                Tu perfil esta actualizado !
                <CloseIcon color='#fafafa' onClick={(e) => { e.stopPropagation(); e.preventDefault(); setMessage(null) }} />
              </button>
      }
      {error  && 
              <button className={`SnackBar ${error ? 'show' : ''}`} style={{ top: "70px" }} onClick={() => setError(null)}>
                Error al actualizar el perfil : {error}
                <CloseIcon color='#fafafa' onClick={(e) => { e.stopPropagation(); e.preventDefault(); setError(null) }} />
              </button>
      }
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          if (newImage) {
                updateProfileAPI(
                  deletedImage,
                  newImage,
                  profileDescription,
                  tiktokUser,
                  igUser
                ).then(res => {
                  console.log(res)
                });
          }
          else {
            updateProfileAPI(
              deletedImage,
              null,
              profileDescription,
              tiktokUser,
              igUser
            ).then(res => console.log("finished b "));
          }
        }}>

        <div className="HeaderSpacer" style={{ height: "56px", width: "30px", }} />
        <div className='ProfileBox' >
          <div className='ProfileHeaderBar'>
            <button className='FullPostHeaderButton' onClick={() => navigate(-1)}>
              <BackIcon />
            </button>
            <div className="ProfileEditTitle"> Editar Perfil </div>
            <button className='FullPostHeaderButton'>
              <div style={{ width: "20px", height: "24px" }} />
            </button>
          </div>
          <div className="ProfileEditBody">
            <div className="ProfileImageChange">
              { newImage ?  <div className="ProfileMiniImage" style={{ backgroundImage:  `url(${URL.createObjectURL(newImage)})`  }} /> :
                            <div className="ProfileMiniImage" style={{ backgroundImage: deletedImage ? "" :   `url(${data?.profile_picture}?${new Date().getTime()})`}} />
              }
              <div className="ProfileMiniFullname">
                {data?.full_name}
              </div>
              <button className="ProfileMiniPhoto" onClick={(e) => { e.preventDefault(); setShow(true) }} >
                Cambiar Foto
              </button>
            </div>
            <div className="ProfileInputBox">
              Bio
              <div className="ProfileInput">
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={3}
                  value={profileDescription}
                  onChange={(e) => setProfileDescription(e.target.value)}
                />
                <div>
                  <div className="ProfileEditContador">
                    {profileDescription ? profileDescription.length : 0} /160
                  </div>
                </div>
              </div>
            </div>
            <div className="ProfileEditSocials">
              Tus links en Redes Sociales

              {data?.ig_user && data?.tiktok_user ?
                <></>
                :
                <div className="ProfileEditBannerIncomplete">
                  <div className="ProfileEditBannerIcon">
                    <DiamondIcon color="#C7506C" />
                  </div>

                  Completa tu perfil para convertirte en una nueva creadora de contenido Cranberry Chic

                </div>

              }
              <div className="ProfileSocialsInput">
                <div className="ProfileSocialsRow">
                  <InstagramIcon width="15px" height="15px" />
                  instagram.com/
                  <div className="ProfileInputLine">
                    <Form.Control
                      type="text"
                      value={igUser}
                      onChange={(e) => setIgUser(e.target.value.toLocaleLowerCase())}
                    />
                  </div>
                </div>
                <div className="ProfileSocialsRow">
                  <TiktokIcon width="20px" height="20px" />
                  tiktok.com/@
                  <div className="ProfileInputLine">
                    <Form.Control
                      type="text"
                      value={tiktokUser}
                      onChange={(e) => setTiktokUser(e.target.value.toLocaleLowerCase())}
                    />
                  </div>
                </div>
                {data?.ig_user && data?.tiktok_user ?
                  <div className="ProfileEditBannerComplete">
                    <div className="ProfileEditBannerIcon">
                      <DiamondIcon color="#696969" />
                    </div>
                    ¿Quieres ser creadora de contenido? Postula ahora y se parte de nuestra comunidad de influencers.
                  </div>
                  :
                  <></>
                }
              </div>
            </div>
          </div>

          <button type="submit" className="RecentPostButton">
            Guardar cambios
          </button>
        </div>

      </Form>
      {show && <NewAvatar  preFoto={data?.profile_picture} setShow ={setShow} image = {image} setImage ={setImage} newImage={newImage} setNewImage={setNewImage} setDeletedImage={setDeletedImage} deletedImage={deletedImage} />}
    </div>
  );
}
