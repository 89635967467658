import React, { useEffect, useState } from "react";
import { LoginAPI } from "../../API";
import { useNavigate } from "react-router-dom";

export default function ResetPassword() {
  let navigate = useNavigate();
  const invalid_email = "Formato mail inválido";
  const register = " Registrate aquí";
  const no_acount = "No tienes cuenta";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showCode, setShowCode] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showEmailMessage, setEmailMessage] = useState("");

  function isEmail(value) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(value)) {
      setEmailMessage(invalid_email);
    } else {
      setEmailMessage("");
    }
  }

  async function handleClick() {
    const logged = await LoginAPI(email, password);
  }

  return (
    <div className="PrincipalBox">
      {showCode && (
        <div className="FormboxPrincipal">
          <p
            style={{
              display: "flex",
              color: "red",
              alignSelf: "flex-end",
              height: 15,
            }}
          >
            {showEmailMessage}
          </p>
          <div className="InputBox">
            <input
              placeholder="Ingresa Código Recibido"
              onChange={(e) => {
                setEmail(e.target.value);
                isEmail(e.target.value);
              }}
            />
          </div>

          <button className="SubmitButton" onClick={() => handleClick()}>
            Válidar Código
          </button>

          <div id="TextAndLink">
            <p id="PrincipalText">No recibiste el código ? </p>
            <button
              className="LinkButton"
              onClick={() => setShowCode(!showCode)}
            >
              Volver a enviar
            </button>
          </div>
        </div>
      )}

      {showCode && (
        <div className="FormboxPrincipal">
          <p
            style={{
              display: "flex",
              color: "red",
              alignSelf: "flex-end",
              height: 15,
            }}
          >
            {showEmailMessage}
          </p>
          <div className="InputBox">
            <input
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
                isEmail(e.target.value);
              }}
            />
          </div>
          <div className="BoxGroupButton">
            <button className="SubmitButton" onClick={() => handleClick()}>
              Enviar Código a Whatsapp
            </button>
            <p id="PrincipalText">ó</p>
            <button className="SubmitButton" onClick={() => handleClick()}>
              Enviar Código a Mail
            </button>
          </div>

          <div id="TextAndLink">
            <p id="PrincipalText">{no_acount}? </p>
            <button
              className="LinkButton"
              onClick={() => navigate("/register")}
            >
              {register}
            </button>
          </div>
        </div>
      )}

      {!showNewPassword && (
        <div className="FormboxPrincipal">
          <div className="InputBox">
            <input
              type={"text"}
              placeholder="Constraseña"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className="InputBox">
           
            <input
              type={"text"}
              placeholder="Confirmar Contraseña"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
        
            <button className="SubmitButton" onClick={() => handleClick()}>
              Cambiar Contraseña
            </button>
        

        </div>
      )}

      <div id="TermsConditionsText">
        <p id="PrincipalTextSmall">
          Al ingresar,aceptas nuestros{" "}
          <button id="LinkButtonVariant">Términos y Condiciones</button> y
          confirmas haber leído nuestra{" "}
          <button id="LinkButtonVariant">Política de privacidad</button>
        </p>
      </div>
    </div>
  );
}
