import React, { useState, useEffect, useRef } from 'react';
import { EmailShareButton, FacebookShareButton, PinterestShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import ShareIcon from '../../assets/SVG/ShareIcon';
import ClipIcon from '../../assets/SVG/ClipIcon';
import CloseIcon from '../../assets/SVG/CloseIcon';
import { WhatsappIcon, PinterestIcon, TiktokIcon } from '../../assets/SVG/SocialIcons';
import MoreIcon from '../../assets/SVG/MoreIcon';

function ShareModal(props) {
    const buttonRef = useRef(null);
    const link  = props.specificLink;

    const title = props.title;
    const contents = props.contents;
    const shareBody = props.shareBody;
    const [showModal,setShowModal]  =  useState(false);
    const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 })
    const [copied, setCopied] = useState(false);


    const handleCloseModal = () => {
       setShowModal(false) // Esta función debería venir desde las props para cerrar el modal
       setCopied(false)
    };

    const handleShowModal = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const buttonRect = buttonRef.current.getBoundingClientRect();
        setModalPosition({ x: buttonRect.x, y: buttonRect.y + buttonRect.height });
        setShowModal(true)  // Esta función debería venir desde las props para abrir el modal
    };

    const calculateLeft = (x) => {
        
        const left = x % window.innerWidth - 312;
        return Math.min(window.innerWidth - 312, Math.max(0, left));
    };

    
      const handleShare = async () => {
        const getFiles = async () => {
            const filesToShare = [];
            
            // Itera sobre los contenidos y descarga los archivos si es necesario
            await Promise.all(contents.map(async (content, index) => {
                try {
                    // Obtener el archivo Blob de la URL externa
                    const fileBlob = await fetch(content.content_file).then((response) => response.blob());
                    const fileName = `cranberrychic_${index}.${content.content_type.split("/")[1]}`;
                    // Crear un objeto File a partir del Blob descargado
                    filesToShare.push(new File([fileBlob], fileName, { type: content.content_type }));
                } catch (error) {
                    console.error('Error al descargar el archivo:', error);
                    throw error; // Lanzamos el error para que se maneje en el nivel superior
                }
            }));
            
            return filesToShare;
        };
    
        try {
            const files = await getFiles();
    
            if (navigator.canShare) {
                await navigator.share({
                    title: title,
                    text: shareBody,
                    url: link,
                    //files: files,
                });
                alert('Contenido compartido exitosamente.');
            } else {
                alert('La función de compartir no está disponible en este navegador.');
            }
        } catch (error) {
            alert('Error al descargar los archivos:', error);
        }
    };
    
    
    


    
    return (
        <>
            <button className="FullPostHeaderButton ShareOpenModal" ref={buttonRef} onClick={(e) => handleShowModal(e)} >
                <ShareIcon width="20px" height="20px" />
            </button>
            {showModal &&
                <div className='ShareModalOverlay' style={{ top: modalPosition.y-70, left:  calculateLeft(modalPosition.x+20) }}>
                    <div className='ShareModalContent'>
                        <div className='ShareModalButton'>
                            <WhatsappShareButton url={link} title={shareBody}>
                                <WhatsappIcon />
                            </WhatsappShareButton>
                        </div>
                        <div className='ShareModalButton'>
                            <PinterestShareButton url={link} media={contents[0].content_file} description={shareBody}>
                                <PinterestIcon />
                            </PinterestShareButton>
                        </div>
                        <div className='ShareModalButtonBox'>
                        <div className='ShareModalButton' onClick={(e) => {
                            e.stopPropagation()
                            navigator.clipboard.writeText(link).then(() => setCopied(true))
                            }}>
                            <ClipIcon />
                            
                        </div>
                        {copied ? "Copiado!" : ""}
                        </div>
                       
                        {navigator.canShare &&
                            <div className='ShareModalButton'>
                                <button onClick={() => handleShare()}>
                                    <MoreIcon />
                                </button>
                            </div>
                        }
                        <div className='CloseModal' onClick={handleCloseModal}>
                            <CloseIcon />
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default ShareModal;





  