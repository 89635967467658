
import React, { useEffect, useState } from "react";
import { LatestPostMagApi } from "../../API";
import Carousel from "react-simply-carousel";
import { Hidden } from "@mui/material";


export default function CranMagEmbed(props) {
  const [data, setData] = useState();
  const [activeSlide, setActiveSlide] = useState(0);
  const size = props.size

  useEffect(() => {
    LatestPostMagApi().then((res) => {
      console.log("res", res);
      if (res) {
        setData(res.slice(0,10));
      }
    });
  }, []);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };


   const HtmlToReactParser = require("html-to-react").Parser;
   let htmlToReactParser = new HtmlToReactParser();

     // Función para cambiar al siguiente slide
  const changeSlide = () => {
    setActiveSlide((prevSlide) => (prevSlide + 1) % 10); // Calcula el siguiente slide
  };

  // Efecto para actualizar el slide activo cada 5 segundos
  useEffect(() => {
    const intervalId = setInterval(changeSlide, 7000); // Cambia el slide cada 5 segundos

    // Limpia el intervalo cuando el componente se desmonta o actualiza
    return () => clearInterval(intervalId);
  }, []); // El efecto se ejecuta solo una vez al montar el componente


   return(
    <div className="MagazineCarouselContainer" style={{width: size}}>
         <Carousel
         containerProps={{
           className: "MagazineCarousel",
         }}
         activeSlideIndex={activeSlide}
         activeSlideProps={{
           className: "ActiveSlide",
         }}
         onRequestChange={setActiveSlide}
         forwardBtnProps={{
           children: "",
           style: {
             width: 0,
             height: 0,
             minWidth: 0,
             alignSelf: "center",
             border: "none",
             background: "none",
           },
         }}
         backwardBtnProps={{
           children: " ",
           style: {
             width: 0,
             height: 0,
             minWidth: 0,
             alignSelf: "center",
             border: "none",
             background: "none",
           },
         }}
         dotsNav={{
           show: false,
           itemBtnProps: {
             style: {
               height: 10,
               width: 10,
             },
           },
           activeItemBtnProps: {
             style: {
               height: 10,
               width: 10,
             },
           },
         }}
         centerMode={true}
         itemsToShow={1}
         speed={400}
       >
        <div
          className="BannerContainer"
          style={{
            width: size + "px",
            overflow:"hidden",
            height: Math.round(size /3) + "px"
          }}
          onClick={() => window.open("https://magazine.cranberrychic.com/producto/cranberry-mag-01-2022/", "_blank", "noopener,noreferrer")}
        >
          <img
            style={{
              width: "100%", // Make the image width 100% of its container
              height: "100%", // Make the image height 100% of its container
              objectFit: "cover" // Ensure the image covers the entire container while maintaining aspect ratio
            }}
            src="https://magazine.cranberrychic.com/wp-content/uploads/2023/08/MAG_Mesa-de-trabajo-1-1536x367.jpg"
            alt="Cranberry Magazine Image"
          />
        </div>

         {data && data.map((item, index) => {
          return(
            <div
            className="BannerContainer"
            style={{
              width: size + "px",
              height: Math.round(size / 3) + "px",
              overflow:"hidden",
            }}
            onClick={() => window.open(item.jetpack_shortlink)}
          >
            <img
              style={{
                width: "100%", // Hacer que el ancho de la imagen sea 100% de su contenedor
                height: "100%", // Hacer que la altura de la imagen sea 100% de su contenedor
                objectFit: "cover", // Asegurar que la imagen cubra todo el contenedor manteniendo la relación de aspecto
                objectPosition: "50% 25%"
              }}
              src={item.jetpack_featured_media_url}
              alt="Cranberry Magazine Image"
            />
          </div>
         )})}
     </Carousel>
      { activeSlide > 0 && data ?
       <div className="BannerTitle" style={{width:size+"px"}}>
       {htmlToReactParser.parse(data[activeSlide-1]?.title?.rendered)}
        </div>  
      :
      <div className="BannerTitle" style={{width:size+"px"}}>
      La nueva Cran Mag 2024
      </div>
      }

      </div>
   )
}

//   if (!full) {
//     return (
//       <div>
//         {" "}
//         <div style={{
//           padding:"10px 10px 10px 15px",
//           fontSize:"0.75em"
//         }}>
//           Descubre lo ultimo de Cranberry Mag
//         </div>
//         {data ? (
//           <div >
//               <Carousel
//           containerProps={{
//             style: {
//               display: "flex",
//               flexFlow: "nowrap",
//               width:'100vw'
//             },
//           }}
//           activeSlideIndex={activeSlide}
//           onRequestChange={setActiveSlide}
//           forwardBtnProps={{
//             children: <div />,
//             style: {
//               width: 0,
//               height: 0,
//               alignSelf: "center",
//               border: "none",
//               background: "none",
//             },
//           }}
//           backwardBtnProps={{
//             children: <div />,
//             style: {
//               width: 0,
//               height: 0,
//               alignSelf: "center",
//               border: "none",
//               background: "none",
//             },
//           }}
//           dotsNav={{
//             show: false,
//           }}
//           itemsToShow={3}
//           speed={400}
//         >
//               {data ? (
//                 data.map((post) => {
//                   var title = htmlToReactParser.parse(post.title.rendered);
//                   if (title.length > 10){
//                     title = title.slice(0,15) + '...'
//                   }
//                   return (
//                   <div style={{display:"flex", flexDirection:"row"}}>
//                     <div className="PostBox" >
                      
//                       <img
//                   style={{
//                     width: 40 + "vw",
//                     maxWidth: "300px",
//                     minHeight:40 *1.5 + "vw",
//                     borderWidth: 0,
//                     objectFit:'cover'
//                   }}
//                   src={post.jetpack_featured_media_url }
//                   alt="image"
//                   loading="lazy"
//                 />
//                 <div className="BarID" id={"bar-id" + data?.id}>
//           <div
//             className="NameandBadgeBox"
            
//           >
//             {title}

//             <button
//               onClick={() => openInNewTab(post.jetpack_shortlink)}
//               style={{
//                 height: "1.5rem",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 backgroundColor: "white",
//                 border: "none",
//               }}
//             >
//               <img
//                 style={{ height: "1.5rem" }}
//                 src={require("../assets/oficial_icons/magazine.png")}
//               />
//             </button>
//           </div>
          
//         </div>
//                     </div>
//                     <div style={{width:5, height:5}} />

//                   </div>  
//                   );
                
//                 })
//               ) : (
//                 <div />
//               )}
//            </Carousel>
//           </div>
          
//         ) : (
//           ""
//         )
//         }
//       </div>
//     );
//   } else {
//     return (
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "space-evenly",
//           marginTop: 30,
//         }}
//       >
//         {data ? (
//           data.slice(0, 4).map((post) => {
//             const title = htmlToReactParser.parse(post.title.rendered);

//             return (
           
//               <div
//                 style={{
//                   border: "2px solid lightgrey ",
//                   display: "flex",
//                   flexDirection: "row",
//                   width: "23%",
//                   borderRadius: 10,
//                   height: 150,
//                   overflow: "hidden",
//                   justifyContent: "center",
//                   alignItems: "center",
//                 }}
//                 onClick={() => openInNewTab(post.jetpack_shortlink)}
//               >
//                 <div
//                   style={{
//                     width: 150,
//                     height: 150,
//                     overflow: "hidden",
//                     display: "flex",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <img
//                     style={{
//                       height: "150px",
//                       borderRadius: "10px",
//                     }}
//                     src={post.jetpack_featured_media_url}
//                     alt="image"
//                   />
//                 </div>
//                 <div
//                   style={{
//                     verticalAlign: "middle",
//                     textAlign: "initial",
//                     width: (innerWidth / 3) * 2,
//                     padding: 10,
//                   }}
//                 > 
//                 {title}
//                 </div>
//               </div>
             
//             );
//           })
//         ) : (
//           <div />
//         )}
//       </div>
//     );
//   }
// }
