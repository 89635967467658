import React, {useEffect , useState} from "react";
import { myBenefitsAPI, fetchMoreAPI, } from "../../API";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import Benefit from "../Components/Benefit";
import NoContentMessage from "../Components/NoContentMessage"
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";



//Icons
import BackIcon from "../../assets/SVG/BackIcon";





function Benefits(props) {
  const [data, setData] = useState(null);
  const [next, setNext] = useState(null);
  const [activeTab,setActiveTab] = useState(0)
  const navigate = useNavigate();

  


  useEffect(() => {
    myBenefitsAPI().then((res) => {
      setData(res.results);
      setNext(res.next);
    });
  }, []);
  
  
  const fetchMore = () => {
    fetchMoreAPI(next,true).then((res) => {
      setData((data) => {
        if (res?.results) {
          return [...data, ...res.results];
        } else {
          return data;
        }
      });
      setNext(res.next);
    });
  };

    return (
      <div className="BenefitLayout">
        <ScrollToTopOnMount />
        <div className="HeaderSpacer" style={{ height: "56px", width: "30px", }} />
        <div className="BenefitsBox">
          <div className='ProfileHeaderBar'>
              <button className='FullPostHeaderButton' onClick={() => navigate(-1)}>
                  <BackIcon color="#696969" />
              </button>
              <div className="ProfileEditTitle"> Mis Beneficios </div>
              <button className='FullPostHeaderButton'>
                  <div style={{width:"20px", height:"24px"}} />
              </button>
          </div>
          <div className="SearchTabsFixed">
            <div className="SearchTab">
              <div className={activeTab === 0 ? "TitleTab TitleTabActive" : "TitleTab"}
              onClick={() => setActiveTab(0)} >
                  Recientes
              </div>
                {activeTab === 0  && <div className="TabRectangle" /> }
            </div>
            <div className="SearchTab">
              <div className={activeTab === 1? "TitleTab TitleTabActive" : "TitleTab"}
              onClick={() => setActiveTab(1)}> 
                Todos
              </div>
                {activeTab === 1  && <div className="TabRectangle" /> }
            </div>
            <div className="SearchTab">
              <div className={activeTab === 2 ? "TitleTab TitleTabActive" : "TitleTab"}
              onClick={() => setActiveTab(2)} > 
                  Expirados
              </div>
                  {activeTab === 2  && <div className="TabRectangle" /> }
            </div>
          </div>
          
          <InfiniteScroll
            dataLength={data ? data.length : 5}
            next={fetchMore}
            hasMore={next ? true : false}
            loader={<h4>Loading...</h4>}
            
          >
            <div className="BenefitsFeed ">
            {data && data.length>0
                ? data.map((benefit, index) => {
                    return (
                      <Benefit key={index} data={benefit} />
                    );
                  })
                : <NoContentMessage customMessage="No tienes beneficios por el momento" /> }
           
            </div>
             
          </InfiniteScroll>
          <div style={{height:"100px",width:"10px"}} />
        </div>
      </div>
  )}



export default Benefits;