//LIBRARY IMPORTS
import React, { useEffect } from "react";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";

import { myWishlistAPI, myWishlistProductAPI ,fetchMoreAPI } from "../../API";
import PostScreen from "../Components/Post";
import InfiniteScroll from "react-infinite-scroll-component";
import SliderMenu from "../Components/SliderMenu";
import Spinner from "react-bootstrap/Spinner";
import Masonry from "react-masonry-css";
import ProductBox from "../Components/ProductBox";
import { useNavigate } from "react-router-dom";
import NoContentMessage from "../Components/NoContentMessage";

function Wishlist(props) {
  const [data, setData] = React.useState(null);
  const [products,setProducts] = React.useState(null);
  const [nextProducts,setNextProduct] = React.useState(null);
  const [next, setNext] = React.useState(null);
  const [activeTab,setActiveTab] = React.useState(0); 
  const [albumFilter, setAlbumFilter] = React.useState(0);
  const [tagFilter, setTagFilter] = React.useState();
  const [columns, setColumns] = React.useState("m");
  let productBox_size = Math.round((window.innerWidth - (35 *2))/2)
  if (productBox_size > 150){
    productBox_size = 150 ;
  } 

  const [breakpointCols, setBreakpointCols] = React.useState({
    default: 4,
    1300: 3,
    900: 2,
  });

  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const res = await myWishlistAPI(albumFilter, tagFilter);
      setData(res.results);
      setNext(res.next);
    };
  
    fetchData();
  
  }, [albumFilter, tagFilter]);

  useEffect(() => {
    const fetchData = async () => {
      const res_products = await myWishlistProductAPI();
      setProducts(res_products.results);
      setNextProduct(res_products.next);
    };
  
    fetchData();
  }, [])
  


  const fetchMore = () => {
    //console.log(next);
    fetchMoreAPI(next, true).then((res) => {
      //console.log(res);
      setData((data) => {
        if (res?.results) {
          return [...data, ...res.results];
        } else {
          return data;
        }
      });
      //console.log(data);
      setNext(res.next);
    });
  };

  const fetchMoreProducts = () => {
    //console.log(next);
    fetchMoreAPI(nextProducts, true).then((res) => {
      //console.log(res);
      setProducts((data) => {
        if (res?.results) {
          return [...data, ...res.results];
        } else {
          return data;
        }
      });
      //console.log(data);
      setNextProduct(res.next);
    });
  };

  return (
    <div>
    {/* <SliderMenu setAlbumFilter={setAlbumFilter} setTagFilter={setTagFilter} fixed={true}/>
     */}
    {/* 
    <Suspense     fallback={<LoadingAnimation />}>
    
    <div> */}
  <ScrollToTopOnMount />
  <div className="HeaderSpacer" style={{height:"98px", width:"30px"}} />
  <div className="SearchTabBox">
      <div className="SearchTabs">
        <div className="SearchTab">
          <div className={activeTab === 0 ? "TitleTab TitleTabActive" : "TitleTab"}
          onClick={() => setActiveTab(0)} >
              Posts
          </div>
            {activeTab === 0  && <div className="TabRectangle" /> }
        </div>
        <div className="SearchTab">
          <div className={activeTab === 1 ? "TitleTab TitleTabActive" : "TitleTab"}
          onClick={() => setActiveTab(1)} > 
            Productos 
          </div>
              {activeTab === 1  && <div className="TabRectangle" /> }
        </div>
    </div>
    </div>
    
    { activeTab=== 0 && data && data.length > 0 && 
      <InfiniteScroll
          dataLength={data ? data.length : 5}
          next={fetchMore}
          hasMore={next ? true : false}
          loader={
            <div className="Loader">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          }
          
        >
          <Masonry
            breakpointCols={breakpointCols}
            className={columns === "l" ? "my-masonry-grid my-masonry-grid-large" : "my-masonry-grid"}
            columnClassName="my-masonry-grid_column"
          >
            {data
              ? data.map((post, index) => {
                  return (
                    <PostScreen className="PostScreen" key={index} data={post} size={columns} index={index}  />
                  );
                })
              : " "}
          </Masonry>
          <div className="HeaderSpacer" style={{height:"98px", width:"30px"}} />
        </InfiniteScroll>

    }
    {activeTab === 0 &&  data && data.length === 0 && 

      <NoContentMessage customMessage="Aún no tienes posts guardados en tu Wishlist." /> 
    }
     
    { activeTab=== 1 && products && products.length>0 && 
      <InfiniteScroll
          dataLength={products ? products.length : 5}
          next={fetchMoreProducts}
          hasMore={nextProducts ? true : false}
          loader={
            <div className="Loader">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          }
          
        >
          <div className="ProductsContainer"> 
            <div className="ProductsBox">
              {products
                ? products.map((producto, index) => {
                    return (
                      <button className="ProductButtonBox" onClick={() => navigate("../../product/"+ producto.id)}>
                      <ProductBox key={index} product={producto} i={producto.id} size={productBox_size} />
                      </button>
                    );
                  })
                : " "}
            </div>
          </div>
          
          <div className="HeaderSpacer" style={{height:"98px", width:"30px"}} />
        </InfiniteScroll>

    }

      {activeTab ===1  &&  products && products.length === 0 && 

      <NoContentMessage customMessage="Aún no tienes posts guardados en tu Wishlist." /> 
      }
        
     
    {/* </div>
    </Suspense> */}
  </div>
);
}


export default Wishlist;
