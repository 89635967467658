import React, { useRef } from 'react';
import FilterIcon from '../../assets/SVG/FilterIcon';
import ChevronDownIcon from '../../assets/SVG/ChevronDownIcon';
import CloseIcon from '../../assets/SVG/CloseIcon';

export default function FilterMenu(props) {
    const catMenu = useRef(null);
    const [openSlide, setOpenSlide] = React.useState(false);
    const { albumFilter, tagFilter, setTagFilter } = props;

    const optionsByAlbum = [
        {
            id: "0",
            type: "todo",
            options: [{label : "moda", value:"1"},
                      {label : "man", value:"30"},
                      {label : "kids", value:"2"},
                      {label : "deco", value:"3"},
                        {label : "beauty", value:"34"},
                    ]
        },
        {
            id: "1",
            type: "look",
            options: [{label : "verano", value:"4"},
                      {label : "evento", value:"29"},
                      {label : "matrimonios", value:"6"},
                      {label : "casual", value:"7"}
                    ]
        },
        {
            id: "3",
            type: "trends",
            options : [{label : "verano", value:"4"},
                      {label : "otoño", value:"8"},
                      {label : "2024", value:"9"},
                      {label : "primavera", value:"21"}
                    ]
        },
        {
            id: "2",
            type: "travel",
            options : [{label : "panoramas", value:"11"},
                      {label : "restaurantes", value:"12"},
                      {label : "hoteles", value:"13"},
                      {label : "Miami", value:"14"},
                      {label : "Londres", value:"15"}
                    ]
        },
        {
            id: "4",
            type: "lifestyle",
            options : [
            {label : "beauty", value:"34"},
            {label : "foodie", value:"16"},
            {label : "deco", value:"3"},
            {label : "crafting", value:"17"},
            {label : "sports", value:"18"}
          ]
        },
    ];

    const options = optionsByAlbum.find(option => option.id === albumFilter)?.options || [];

    const closeOpenMenus = (e) => {
        if (catMenu.current && openSlide && !catMenu.current.contains(e.target)) {
            setOpenSlide(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener("mousedown", closeOpenMenus);
        return () => {
            document.removeEventListener("mousedown", closeOpenMenus);
        };
    }, [openSlide]);

    const handleTagFilterClick = (subOption) => {
        if (tagFilter.includes(subOption)) {
            setTagFilter(tagFilter.filter(item => item !== subOption));
        } else {
            setTagFilter([...tagFilter, subOption]);
        }
        setOpenSlide(false);
    };

    return (
        <div className="FilterMenu">
            {!openSlide ? (
                <div className="FilterButton" onClick={() => setOpenSlide(true)}>
                    <FilterIcon />
                    {tagFilter.length>0 && <div className='BadgedNumber'> {tagFilter.length} </div> }
                    <ChevronDownIcon />
                </div>
            ) : (
                <div className="FilterOptionsMenu" ref={catMenu}>
                    <div className="SmallHeader">Filtrar por      <button className='SmallestButton' onClick={() => setOpenSlide(false)}> <CloseIcon width='20px' height='20px' />  </button></div>
                    {options.map((subOption) => (
                        <div key={subOption} className={`FilterOptions ${tagFilter.includes(subOption.value) ? 'Selected' : ''}`} onClick={() => handleTagFilterClick(subOption.value)}>
                            <input
                                type="checkbox"
                                className='myCheckBox'
                                id={`myCheckbox_${subOption.value}`}
                                checked={tagFilter.includes(subOption.value)}
                                readOnly
                            />
                            <label htmlFor={`myCheckbox_${subOption.value}`} className="checkbox-label">{subOption.label}</label>
                        </div>
                    ))}
                    <div className='FilterOptionsFinal'>
                        <div onClick={() => { setOpenSlide(false); setTagFilter([]) }} className='FilterOptionsButton'>
                            Ver todo
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
