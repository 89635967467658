import React, { useEffect, useState } from "react";
import { LoginAPI } from "../../API";
import { useNavigate } from "react-router-dom";


export default function Registro() {
  let navigate = useNavigate();
  const invalid_email = "Formato mail inválido";
  const log_in_message = "Ingresar ";
  const forgot_password = "Olvidé mi contraseña";
  const register = " Registrate aquí";
  const no_acount = "No tienes cuenta";
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showProblem, setProblem] = useState(false);
  const [showEmailMessage, setEmailMessage] = useState("");

  function isEmail(value) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(value)) {
      setEmailMessage(invalid_email);
    } else {
      setEmailMessage("");
    }
  }



  async function handleClick() {
    const logged = await LoginAPI(email, password);
    // if (logged){
    //   navigation.navigate("Main")
    // }
  }


  return (
    <div className="PrincipalBox">
      <div className="FormboxPrincipal">
        <div className="InputBox">
          <input
            placeholder="Nombre"
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
        </div>

        <div className="InputBox">
          <input
            placeholder="Apellido"
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>

        <div className="InputBox">
          <input
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value);
              isEmail(e.target.value);
            }}
          />
        </div>

        <div className="InputBox">
          <input
            placeholder="Nombre de usuario"
            onChange={(e) => {
              setUserName(e.target.value);
            }}
          />
        </div>

        <div className="InputBox">
          <input
            placeholder="Whatsapp (+56123456789) "
            onChange={(e) => {
              setUserName(e.target.value);
            }}
          />
        </div>

        <div className="InputBox">
          <input
            placeholder="Pais"
            onChange={(e) => {
              setCountry(e.target.value);
            }}
          />
        </div>

        <div className="InputBox">
          <input
            type={!showPassword ? "password" : "text"}
            autocomplete="new-password"
            placeholder="Nueva Constraseña"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <button
            id="IconText"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            
          </button>
        </div>
        <div className="InputBox">
          <input
            type={!showPassword2 ? "password" : "text"}
            autocomplete="new-password"
            placeholder="Confirma Constraseña"
            onChange={(e) => {
             //console.log("")
            }}
          />

          <button
            id="IconText"
            onClick={() => {
              setShowPassword2(!showPassword2);
            }}
          >
           
          </button>
        </div>
        <button className="SubmitButton" onClick={() => handleClick()}>
          Crear Cuenta
        </button>

        <div id="TextAndLink">
          <p id="PrincipalText">Ya tienes cuenta ?</p>
          <button className="LinkButton" onClick={() => navigate("/login")}>
            Iniciar Sesión
          </button>
        </div>
      </div>

      <div id="TermsConditionsText">
        <p id="PrincipalTextSmall">
          Al ingresar,aceptas nuestros{" "}
          <button id="LinkButtonVariant">Términos y Condiciones</button> y
          confirmas haber leído nuestra{" "}
          <button id="LinkButtonVariant">Política de privacidad</button>
        </p>
      </div>
    </div>
  );
}


