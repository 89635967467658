import React, { useEffect } from 'react';

const ScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Hacer scroll hacia arriba
  }, []); // Se ejecuta solo una vez, al montar el componente

  return null; // Este componente no renderiza nada
};

export default ScrollToTopOnMount;
