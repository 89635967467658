import React, { useEffect, useState } from "react";
import { SearchAllAPI,PostAPI } from "../../API";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FollowButton from "../Components/FollowButton";

import ProductBox from "../Components/ProductBox";
import ProductModal from "../Components/ProductModal";
import PostScreen from "../Components/Post";
import Masonry from "react-masonry-css";
import SearchIcon from "../../assets/SVG/SearchIcon";
import CloseIcon from "../../assets/SVG/CloseIcon";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";
import NoContentMessage from "../Components/NoContentMessage";

export default function Search() {
  let navigate = useNavigate();
  const search = useParams()?.name;
  const [data, setData] = useState();
  const [newSearch, setNewSearch] = useState(search)
  const [activeTab,setActiveTab] = useState(1);
  const [showModal,setShowModal] = useState();
  const [posts,setPosts] = useState([]) ;
  const [users,setUsers] = useState([]) ;
  const [products,setProducts]  = useState([]) ;
  const [productID, setProductID] = useState();
  const [columns, setColumns] = React.useState("m");
  const [breakpointCols, setBreakpointCols] = React.useState({
    default: 2,
    1300: 2,
    900: 2,
  });

  useEffect(() => {
    SearchAllAPI(search.toLowerCase()).then((res) => {
      setProducts(res.recommendations);
      setPosts(res.posts)
      setUsers(res.users)
      console.log(res);
    });
  }, [search]);


  useEffect(() => {
    const postsLength = posts? posts.length : 0;
    const productsLength = products? products.length : 0 ;
    const usersLength = users? users.length : 0 ;
    
    const mayor = Math.max(postsLength, productsLength, usersLength)
    console.log(mayor)
    if (postsLength === mayor ) {
      setActiveTab(0);
    } else if ( usersLength === mayor) {
      setActiveTab(1);
    } else if (productsLength === mayor ) {
      setActiveTab(2);
    }
  }, [posts, products, users]);


  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const uniquePostIds = new Set(products.flatMap(product => product.posts)); // Crea un conjunto de postIds únicos
        const postsPromises = Array.from(uniquePostIds).map(PostAPI); // Mapea los postIds únicos y realiza la búsqueda de las publicaciones
        const fetchedPosts = await Promise.all(postsPromises);
        setPosts(fetchedPosts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };
  
    fetchPosts();
  }, [products]);
  
  



  const handleEnter = (event) => {
    if (event.key === "Enter") {
      navigate('/search/'+ newSearch)
    }

  };


  const handleOpenModal = () => {
    setShowModal(true);
};


  const handleCloseModal = () => {
    setShowModal(false);
};




  return (
    <div className="SearchLayout">
      <ScrollToTopOnMount />
      <div className="HeaderSpacer" style={{height:"98px", width:"30px"}} />
        <div className="SearchBox">
          <div className="ProfileInputBox">
              <div className="PostFormMultiSelect">
                  <SearchIcon width="20px" height="20px" />
                  <input type="text" placeholder="Buscar Tendencias, Inspiracion"  value={newSearch}onChange={(e)=> setNewSearch(e.target.value)} 
                      onKeyDown={handleEnter} />
                  { newSearch ? 
                      <button className="CloseMiniButton" onClick={(e) => {
                        e.preventDefault();
                        setNewSearch(""); // Borrar el contenido del campo de entrada
                      }}>
                        <CloseIcon width="20px" height="20px" />
                      </button>
                      : 
                      <div style={{height:"20px", width:"20px"}} />
                      }
              </div>
            </div>
            <div className="SearchTabBox">
            <div className="SearchTabs">
              <div className="SearchTab">
                <div className={activeTab === 0 ? "TitleTab TitleTabActive" : "TitleTab"}
                onClick={() => setActiveTab(0)} >
                   Post 
                </div>
                  {activeTab === 0  && <div className="TabRectangle" /> }
              </div>
              <div className="SearchTab">
                <div className={activeTab === 1? "TitleTab TitleTabActive" : "TitleTab"}
                onClick={() => setActiveTab(1)}> 
                  Perfiles
                </div>
                  {activeTab === 1  && <div className="TabRectangle" /> }
              </div>
              <div className="SearchTab">
                <div className={activeTab === 2 ? "TitleTab TitleTabActive" : "TitleTab"}
                onClick={() => setActiveTab(2)} > 
                  Productos 
                </div>
                    {activeTab === 2  && <div className="TabRectangle" /> }
              </div>
            </div>
            </div>


            {activeTab ===0 && posts && posts?.length > 0  &&
            <div className="PostSearchFeed">
             <Masonry
             
             breakpointCols={breakpointCols}
             className={columns === "l" ? "my-masonry-grid my-masonry-grid-large" : "my-masonry-grid"}
             columnClassName="my-masonry-grid_column" >
             {posts.map((post, index) => 
                     <PostScreen className="PostScreen" key={"post_" +index} data={post} size={columns} index={index} />
              )}
            </Masonry>
            </div>
            }
            {activeTab ===0 && posts && posts?.length === 0  &&
            <NoContentMessage customMessage={"No se encontraron posts  relacionados a la búsqueda :  " + search} />
            }
            {activeTab ===1 && users && users?.length > 0  &&
              <div className="UsersFeed">
              {users.map((usuarios, index) => (
                <div className="UserRow" key={"user_" +index}  >
                  <div className="UserInfo" onClick={() => navigate("../../profile/"+ usuarios.id)}> 
                    <div className="ProfileMiniImage" style={{backgroundImage: "url(" + usuarios?.profile_picture + ")",}} />
                    <div className="ProfileMiniFullname">
                        {usuarios?.full_name}
                        <div className="ProfileMiniSubtitles"> 25posts </div>
                    </div>
                  </div>
                  
                  <FollowButton user_is={usuarios.id} />
                </div>
              ))}
              </div>
            }
            {activeTab ===1 && users && users?.length === 0  &&
            <NoContentMessage customMessage={"No se encontraron perfiles de usuario relacionados a la búsqueda:  " + search} />
            }
            {activeTab ===2 && products && products?.length > 0  &&
              <div className="ProductosFeed" >
              {products.map((producto,index) => (
              <button onClick={() => navigate("../../product/"+ producto.id)}>
                <ProductBox key={"prod_" + index} product={producto} handleOpenModal={handleOpenModal} setProductID={setProductID}  />
              </button>
              ))}
              </div>
            }
            {showModal &&
                <ProductModal productID={productID}  handleCloseModal={handleCloseModal} />
            } 
            {activeTab ===2 && products && products?.length === 0  &&
            <NoContentMessage customMessage={"No se encontraron productos relacionados a la búsqueda : " + search}    />
            }   
        </div>
    </div>
  );
}
