import { useState, useEffect } from "react";
import React from "react";
import { MultiSelect } from "react-multi-select-component";
import Autocomplete from "react-google-autocomplete";
import Form from "react-bootstrap/Form";
import { CreatePostAPI, MeAPI } from "../../API";
import { useNavigate } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import Switch from "react-switch";
import Spinner from "react-bootstrap/Spinner";
import Select from 'react-select';
import PinIcon from "../../assets/SVG/PinIcon"

export default function PostForm(props) {
  const [categories, setCategories] = useState([]);
  const [albums, setAlbums] = useState([]);
  const files = props.files;
  const ratioSquare = props.ratioSquare;
  const [location, setLocation] = useState(null);
  const [description, setDescription] = useState("");
  const [rate, setRate] = useState(null);
  const [response, setResponse] = useState(null);
  const [isGlobal, setIsGlobal] = useState(true);
  const [isTravel, setIsTravel] = useState(false);
  const [openModal , setOpenModal] = useState(false);

  //Errores 
  const [files_error,setFile_error]= useState(null);
  const [categories_error,setCategories_error]= useState(null);
  const [albums_error,setAlbum_errors]= useState(null);
  const [place_error,setPlace_error]= useState(null);
  const [rate_error,setRate_error] = useState(null);
  const [uploadErrors, setUploadErros] = useState(null); 


  let navigate = useNavigate();
  const optionsByAlbum = [
    {"label":"moda","value":"moda"},
    {"label":"man","value":"man"},
    {"label":"kids","value":"kids"},
    {"label":"deco","value":"deco"},
    {"label":"verano","value":"verano"},
    {"label":"gala","value":"gala"},
    {"label":"matrimonios","value":"matrimonios"},
    {"label":"casual","value":"casual"},
    {"label":"panoramas","value":"panoramas"},
    {"label":"restaurantes","value":"restaurantes"},
    {"label":"hoteles","value":"hoteles"},
    {"label":"Miami","value":"Miami"},
    {"label":"Londres","value":"Londres"},
    {"label":"verano","value":"verano"},
    {"label":"otoño","value":"otoño"},
    {"label":"2024","value":"2024"},
    {"label":"2025","value":"2025"},
    {"label":"foodie","value":"foodie"},
    {"label":"deco","value":"deco"},
    {"label":"crafting","value":"crafting"},
    {"label":"sports","value":"sports"},
    {"label":"beauty","value":"beauty"}
]


  
  
  var options = [
    //{ label: "Todo", value: "0" },
    { label: "Look", value: "1" },
    { label: "Travel", value: "2" },
    { label: "Lifestyle", value: "4" },
  ];

  const optionsGrade = [
    { label: "1 estrella", value: 1 },
    { label: "2 estrellas", value: 2 },
    { label: "3 estrellas", value: 3 },
    { label: "4 estrellas", value: 4 },
    { label: "5 estrellas", value: 5 },
  ];

  useEffect(() => {
    if (typeof response?.id === "number") {
      setOpenModal(false);
      const me = JSON.parse(localStorage.getItem("me"));
      navigate("../../@"+me.username+"/post/"+response.id)
      }
    else if (response){
      setUploadErros("Oops! Hubo un problema con el servidor, intentalo nuevamente")
    }
  }, [response]);


  useEffect(() => {
    setFile_error( files_error && files.length > 0? null : files_error);
    setCategories_error(categories_error && categories.length  > 0 ? null :  categories_error);
    setAlbum_errors(albums_error && albums.length > 0 ? null : albums_error);
    setRate_error(rate_error && isTravel && rate ? null : rate_error) ;
    setPlace_error(place_error && isTravel && location ? null : place_error); 

  }, [albums,files,categories,location,rate]);

  useEffect(() => {
    if (albums.some(album => album.value === "2")) {
      setIsTravel(true);
    }
    else{
      setIsTravel(false);
    }
    setCategories([])
    albums.forEach(selectedAlbum => {
      const album = optionsByAlbum.find(album => album.value === selectedAlbum.value);
      if (album) {
        setCategories(prevCategories => [...prevCategories, ...album.options]);
      }
    });

  }, [albums]);

  const createPostSequencially = async () => {
    const is_complete = files.length > 0  && albums.length > 0 && (isTravel ? (rate  && location) : true);
      if (!is_complete){
        console.log("not completed")
        setFile_error(files.length === 0 ? "Debes subir por lo menos un archivo multimedia" : null );
        setAlbum_errors(albums.length === 0 ? "Debes seleccionar por lo menos un album" : null);
        setRate_error(isTravel && !rate ?  "Debes seleccionar una nota para la experiencia" : null);
        setPlace_error(isTravel && !location ? "Debes seleccionar un lugar para la experiencia" : null); 
      }
      else{
        setOpenModal(true)
        const post = await CreatePostAPI(
          files,
          categories,
          albums,
          location,
          description,
          rate?.value,
          isGlobal,
          ratioSquare,
        );
        setResponse(post)
      }
      
  };

  return (
    
      <Form className="PostForm"
            onSubmit={(e) => {
              e.preventDefault();
              createPostSequencially();
            }}
        >
        {files_error ? <span className="ErrorText"> {files_error} </span> : ""}
        <div className="ProfileInputBox">
          Selecciona uno o más albums  {albums_error ? <span className="ErrorText"> {albums_error} </span> : ""}
          <div className={albums_error ? "PostFormMultiSelect ErrorBorder" : "PostFormMultiSelect"}>
            <MultiSelect
              options={options}
              value={albums}
              onChange={setAlbums}
              labelledBy="Albums"
              closeOnChangedValue={false}
              disableSearch={true}
              hasSelectAll={false}
            />
          </div>
        </div>
        <div className="ProfileInputBox">
          Descripción 
            <div className="ProfileInput">
              <Form.Control 
                  type="text"
                  as="textarea"
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
              />
              
            <div>
                <div className="ProfileEditContador">
                  {description ? description.length : 0 } /160
                </div>
            </div>
            </div>
          </div>
          {
            isTravel && 
            <div className="ProfileInputBox">
              Ubicación   {place_error ? <span className="ErrorText"> {place_error} </span> : ""}
              <div className={place_error ? "PostFormMultiSelect ErrorBorder" : "PostFormMultiSelect"}>
                <PinIcon/>
              <Autocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                  options={{ types: ["establishment"], fields: ["ALL"] }}
                  onPlaceSelected={(place) => {
                    setLocation(place);
                }}
                />
              </div>
            </div>
          }

          {
            isTravel && 
            <div className="ProfileInputBox">
              Nota {rate_error ? <span className="ErrorText"> {rate_error} </span> : ""}
              <div className="HelpText">
              Siendo 1 estrella la peor experiencia y 5 estrellas la  mejor 
              </div>
              <div className={rate_error ? "PostFormMultiSelect ErrorBorder" : "PostFormMultiSelect"}>
              <Select 
                options={optionsGrade} 
                value={rate}
                onChange={(item) => setRate(item)} />
              </div>
              
            </div>
          }
          
          
        <div className="ProfileInputBox">
          Agrega categorias a tu publicacion!{categories_error ? <span className="ErrorText"> {categories_error} </span> : ""}
          <div className={categories_error ? "PostFormMultiSelect ErrorBorder" : "PostFormMultiSelect"}>
                {/* <TagsInput
                  value={categories}
                  onChange={setCategories}
                  name="categories"
                  placeHolder={categories.length>0 ?"" :"Ejemplo : Boda  playa  mujer miami"}
                  separators={["Enter",","," "]}
                  isEditOnRemove={true}
                /> */}
                <MultiSelect
                options={optionsByAlbum}
                value={categories}
                onChange={setCategories}
                labelledBy="Categories"
                closeOnChangedValue={false}
                hasSelectAll={false}
                />
          </div>
        </div>
        {/* <div className="ProfileInputBox">
          Cuentanos sobre tu look!  {categories_error ? <span className="ErrorText"> {categories_error} </span> : ""}
          <div className={categories_error ? "PostFormMultiSelect ErrorBorder" : "PostFormMultiSelect"}>
                <MultiSelect
                options={optionsByAlbum}
                value={categories}
                onChange={setCategories}  
                labelledBy="Categories"
                closeOnChangedValue={false}
                hasSelectAll={false}
                />
          </div>
        </div> */}
        <div className="Divider" />
        <div className="FormOptions">
        Compartir Post (Puede aparecer en Feed principal Cranberry donde todos lo pueden ver) <Switch  onColor={"#C7506C"} onHandleColor={"#EBC7D0"} uncheckedIcon={false} handleDiameter={12} checkedIcon={false} width={40} height={20} checked={isGlobal}  onChange={() => setIsGlobal(!isGlobal)}/>
        </div>
        <button type="submit" className="RecentPostButton" > 
               Publicar
        </button>
        {openModal && 
          <div className="LoadingModal">
              { uploadErrors ?
              (
              <div className="LoadingModalBox">
               <span>{uploadErrors} </span>
                <button className="RecentPostButton" onClick={() => navigate("../../new")}> Volver </button>
              </div>
              ) : (
              <div className="LoadingModalBox">
               <span>Estamos subiendo tu post </span>
               <Spinner />
              </div>
              )}
           
          </div>
        }
      </Form>
  )}  