// LIBRARY IMPORTS
import React, { useEffect, useState } from "react";
import { ProductsAPI,PostAPI, GetPlaceAPI } from "../../API";
import { useNavigate, useParams } from "react-router-dom";

// MY CODE IMPORTS
//import RecomendationMenu from "../Components/RecomendationMenu";

import RecomendationMenu from "../Components/RecomendationMenuV3";
import ShareModal from "../Components/ShareModal";
import CarouselContent from "../Components/CarouselContent";
import WishlistModal from "../Components/WishlistModal";
import FollowButton from "../Components/FollowButton";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";
import TrashModal from "../Components/TrashModal";


// My icons
import BackIcon from "../../assets/SVG/BackIcon";
import PinIcon from "../../assets/SVG/PinIcon";
import StartIcon from "../../assets/SVG/StarIcon";


function FullPostScreen(props) {
  const [data, setData] = useState();
  const [place, setPlace] = useState();
  const [products, setProducts] = useState();
  const { id } = useParams();
  const [me, setMe] = useState();
  const [showMore,setShowMore] =React.useState(false);

  let isMobile;
  let box_size;
  let image_size;
  const { innerWidth } = window;

  useEffect(() => {
    if(!me){
        setMe(JSON.parse(localStorage.getItem("me")));
      }
  }, [me]);


  isMobile = true;
  box_size = innerWidth - 16;
  if (box_size > 500){
    box_size = 500;
  }
  image_size = box_size - 102;

  const navigate = useNavigate();

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleGoBack = () => {
    navigate(-1);
};


  useEffect(() => {
    PostAPI(id).then((res) => {
      if (res) {
        setData(res);
        const place = res.place;
        if(place){
          GetPlaceAPI(place).then((res_place) => setPlace(res_place));
        }
      }
    });
    ProductsAPI().then(res => {setProducts(res.results); console.log(res.results)})
  }, [id]);


  useEffect(() => {
    if (data?.place) {
      GetPlaceAPI(data.place).then((res) => {
        setPlace(res);
      });
    }
  }, [data]);



  

  

  function RateStars(props) {
    const number = props.number;
    const stars = [];
    let i;
    for (i = 1; i <= number; i++) {
      stars.push(
        <StartIcon key={"star" + i }filled={true} />
      );
    }
    for (let j = 5; j >= i; j--) {
      stars.push(
        <StartIcon key={"star"+ j}filled={false} />
      );
    }
    return <div style={{ display: "flex", flexDirection: "row" }}>{stars}</div>;
  }

  function separarElementos(textoHTML) {
    // Eliminar todas las etiquetas HTML y obtener solo el contenido de texto dentro de ellas
    const contenidoLimpio = textoHTML.replace(/<[^>]*>/g, '');
    
    // Dividir el contenido limpio en elementos basados en saltos de línea, puntos, comas u otros caracteres
    const elementos = contenidoLimpio.split(/[.,;\n]/);
    
    // Eliminar espacios en blanco al principio y al final de cada elemento y eliminar elementos vacíos
    const elementosFiltrados = elementos.map(elemento => elemento.trim()).filter(elemento => elemento !== '');

    return elementosFiltrados.join("")
}

  
  const HtmlToReactParser = require("html-to-react").Parser;
  const elementosSeparados = data   && data.description ? separarElementos(data.description) : "";
  console.log(elementosSeparados)
  let htmlInput = data?.description ;
  let htmlToReactParser = new HtmlToReactParser();
  let reactElementMini = elementosSeparados;
  let reactElementFull = htmlToReactParser.parse(htmlInput);
  console.log(reactElementFull)

  if (isMobile) {
    return (
      <div className="FullPostLayoutMobile">
        <ScrollToTopOnMount />
        <div className="HeaderSpacer" style={{ height: "56px", width: "100vw" }} />
        <div className="FullPostBoxMobile" style={{ width: box_size }} >
          <div className="FullPostHeader">
            <div className="FullPostHeaderLeft">
              <button className="FullPostHeaderButton" onClick={() => handleGoBack()}>
                <BackIcon />
              </button>
              <ShareModal specificLink={window.location.href} id={data?.id} contents={data?.contents} shareBody={"Mira este post en Cranberry Chic : @"+ data?.user?.full_name+":"+data?.description}/>
            </div>
            <div className="FullPostHeaderRowButton">
              <FollowButton user_id={data?.user?.id} />
              {data?.user?.id  === me?.id && 
                <TrashModal post_id ={data?.id} me={me?.username} />
              }
              <WishlistModal id={id} img={data?.contents[0]} />
            </div>
          </div>
          {
            data && data.contents.length > 0 &&
          <div className="FullPostContentCarousel">
            <CarouselContent id={id} data={data?.contents} size={image_size} mini={false} ratioSquare={data?.contents[0].content_ratio==="1:1" }/>
          </div>
          }
          <div className="FullPostContentHeader">
            <div className="FullPostContentCreator">
              <button className="FullPostContentCreatorName" onClick={() => navigate("/@"+data?.user?.username)}>
                {data?.user?.full_name}
              </button>
              <div className="FullPostContentCreatorBagde"> </div>
            </div>
            <div className="FullPostContentHeaderSub">
              {place ? (
                <button
                  className="PlaceButton"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => openInNewTab(place?.url)}
                >
                  <PinIcon />
                  {place.city},{place.country}
                </button>
              ) : (
                <button
                  className="PlaceButton"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {data?.user?.country}
                  
                </button>
              )}
            </div>
          </div>
          <div className="TextContent">
            {place ? (
              <div className="PlaceContent">
                <div className="PlaceContentHeader">
                  <button
                    className="PlaceButtonTitle"
                    onClick={() => openInNewTab(place?.url)}
                  >
                    {place.name}
                  
                  </button>
                  <RateStars  number={data?.grade} />
                </div>
                <div className="PlaceContentSub">
                  {place.streetAddress}
                </div>
                <div className="Divider" />
            
              </div>
            ) : (
              <div />
            )}
           
            <div>
              {
                data?.description && data?.description!=="null"&&
                <div className="FullPostDescription">
                   { showMore ?
                   <div>
                    {reactElementFull}
                    <button className="LinkButtonGrey" onClick={() => setShowMore(false)}>  ver menos </button>
                    </div>
                      :
                      <>
                      <div className="miniText" >
                        {reactElementMini}
                      </div>
                      <button className="LinkButtonGrey" onClick={() => setShowMore(true)}>  ver más </button>
                      </>
                    }     
                </div>

              }
             
            </div>
          </div>
              { data?.recommendations?.length > 0   ? 
                 <RecomendationMenu  title="Recomendados Cranberry" recommendations={data?.recommendations} products={[]} box_size={box_size}  postID={data?.id}/> 
                 :
                 <RecomendationMenu  title="Recomendados Cranberry" recommendations={[]}   box_size={box_size}  postID={data?.id}/>
                
              }
          
        <div className="Spacer" style={{ height:"20px" , width:"10px"}} />     
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default FullPostScreen;




