import React, { useState, useEffect } from 'react';
import {  isWishlistProductAPI } from "../../API";
// Icons
import BookmarkIcon from "../../assets/SVG/BookmarkIcon";


export default function ProductBox(props) {
    const { product, i,size } = props;
    const [isWish, setIsWish] = useState(false);
    
    useEffect(() => {
        if(product.id) {
            isWishlistProductAPI(product.id).then((res) => {
                if (res) {
                    setIsWish(res.status);
                    console.log(res.status);
                }
            });
        }
    }, []); // Deja el array de dependencias vacío para que se ejecute solo una vez
    

    return (
        <div
            key={i}
            className="ProductButton"
            style={{
                backgroundImage: `url(${product.content_file})`,
                width : size ? size  + "px" : "auto" ,
                height : size ? size + "px" : "auto",
                marginRight: size ? "0px" : "12px"
                }}
        >
            {isWish && (
                <div className='WishlistProduct'>
                    <BookmarkIcon height="20px" width="20px" color={"#B33553"} fill={"#EBC7D0"} />
                </div>
            )}
        </div>
       
    );
}
