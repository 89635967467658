import "./app/css/StandarStyle.css";
import Login from "./app/screens/main/Login";
import Registro from "./app/screens/main/Register";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  refreshToken,
  MeAPI,
} from "./app/API";
import React, { useEffect, useState } from "react";
import { PrivateRoute } from "./app/PrivateRoute";

import FeedScreen from "./app/screens/MainFinal/FeedScreen";
import Wishlist from "./app/screens/MainFinal/Wishlist";
import FullPostScreen from "./app/screens/MainFinal/FullPostScreen";
import EditProfile from "./app/screens/MainFinal/EditProfile";
import Profile from "./app/screens/MainFinal/Profile";
import SearchBar from "./app/screens/MainFinal/SearchBar";
import Benefits from "./app/screens/MainFinal/Benefits";
import Search from "./app/screens/MainFinal/Search";
import NavBar from "./app/screens/layout/NavBar";
import Bottombar from "./app/screens/layout/BottomBar";
import AlbumPosts from "./app/screens/MainFinal/AlbumFeed";
import Notifications from "./app/screens/MainFinal/Notifications";
import AddPhotos from "./app/screens/MainFinal/AddPhotos";
import ModalLogin from "./app/screens/Components/ModalLogin";
import ModalCreator from "./app/screens/Components/ModalCreator";
import Product from "./app/screens/MainFinal/Product";

// Me falta
import Activate from "./app/screens/main/Activate";
import ResetPassword from "./app/screens/main/ResetPassword";


function App() {
  const [me, setMe] = useState();
  const [show, setShow] = useState(false);
  const [showCreator, setShowCreator] = useState(false);

  useEffect(() => {
    if(!me){
      const is_loged = MeAPI();
      if (is_loged) {
        setMe( JSON.parse(localStorage.getItem("me")));
      }
      if (!is_loged) {
        const refresh = localStorage.getItem("refresh");
        refreshToken(refresh);
      }
    }
  }, [me]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const token = localStorage.getItem("token");
      if (token) {
        const refresh = localStorage.getItem("refresh");
        console.log("Refreshing token");
        refreshToken(refresh);
      }
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);



  return (
    <div className="App">
      <Router>
        <div>
          <NavBar setShow={setShow}  me={me} />
          <Routes>
            <Route path="/" element={<Navigate to="/feed" replace />} />
            <Route exact path="/feed/:albumFilter/:tagFilter/:currentFromUrl" element={<FeedScreen />} />
            <Route exact path="/feed/:albumFilter/:tagFilter" element={<FeedScreen />} />
            <Route exact path="/feed/:albumFilter/" element={<FeedScreen />} />
            <Route path="/feed" element={<FeedScreen />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Registro />} />
            <Route exact path="/@:name/post/:id" element={<FullPostScreen />} />
            <Route exact path="/@:name" element={<Profile />} />
            <Route exact path="/product/:id" element={<Product />} />
            <Route exact path="/search/:name" element={<Search />} />
            <Route exact path="/searchbar" element={<SearchBar />} />
            <Route exact path="/activate/:params" element={<Activate />} />
            <Route exact path="/activate/" element={<Activate />} />
            <Route exact path="/resetpassword" element={<ResetPassword />} />
            <Route exact path="/@:name/posts/:album" element={<AlbumPosts />} />
            <Route exact path="/@:name/posts/" element={<AlbumPosts />} />

            <Route exact path="/" element={<PrivateRoute />}>
              <Route exact path="/@:name/edit" element={<EditProfile />} />
              <Route exact path="/mywishlist" element={<Wishlist />} />
              <Route exact path="/notifications" element={<Notifications />} />
              <Route exact path="/new" element={<AddPhotos />} />
              <Route exact path="/benefits" element={<Benefits />} />
            </Route>
          </Routes>
        </div>
        <Bottombar setShow={setShow} setShowCreator={setShowCreator} me={me} />

        {show && <ModalLogin setShow={setShow} />}
        {showCreator && <ModalCreator setShow={setShowCreator} />}
      </Router>
    </div>
  );
}

export default App;
