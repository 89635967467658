// LIBRARY IMPORTS
import React, { useEffect } from "react";
import { MyFeedAPI, fetchMoreAPI } from "../../API";
import FilterMenu from "../Components/FilterMenu";
import PostScreen from "../Components/Post";
import SizeMenu from "../Components/SizeMenu";
import InfiniteScroll from "react-infinite-scroll-component";
import SliderMenu from "../Components/SliderMenu";
import Masonry from "react-masonry-css";
import Spinner from "react-bootstrap/Spinner";
import NoContentMessage from "../Components/NoContentMessage";

import { useNavigate, useParams } from 'react-router-dom';


function FeedScreen(props) {
  const params = useParams();
  var options = [
    { label: "todo", value: "0" },
    { label: "look", value: "1" },
    {label: "trends", value: "3" }, //solo sube admins
    { label: "travel", value: "2" },
    { label: "lifestyle", value: "4" },
  ];
  const selectedOption = options.filter(option => option.label ===  params.albumFilter)[0]
  const [data, setData] = React.useState(null);
  const [next, setNext] = React.useState(null);
  const [albumFilter, setAlbumFilter] = React.useState(selectedOption?.value || "0");
  const [tagFilter, setTagFilter] = React.useState(params.tagFilterFromUrl ? params.tagFilterFromUrl.split(",") : []);
  const [columns, setColumns] = React.useState("m");

  const [breakpointCols, setBreakpointCols] = React.useState({
    default: 4,
    1300: 3,
    900: 2,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const res = await MyFeedAPI(albumFilter, tagFilter);
      setData(res.results);
      setNext(res.next);
    };
    // Desplazar al inicio de la ventana
    window.scrollTo({
      top: 0,
      behavior: "smooth" // O "auto" para un desplazamiento instantáneo
    });
    fetchData();
    if(albumFilter && albumFilter !== "0" ){
      navigate(`/feed/${ options.filter(option => option.value ===  albumFilter)[0]?.label}/`);
    }
    if(albumFilter && albumFilter === "0" ){
      navigate(`/feed/`);
    }

  }, [albumFilter, tagFilter]);


  useEffect(() => {
        // Desplazar al inicio de la ventana
        window.scrollTo({
          top: 0,
          behavior: "smooth" // O "auto" para un desplazamiento instantáneo
        });
      setTagFilter([])
  }, [albumFilter]);
  

  const fetchMore = () => {
    fetchMoreAPI(next).then((res) => {
      setData((data) => {
        if (res?.results) {
          return [...data, ...res.results];
        } else {
          return data;
        }
      });
      setNext(res.next);
    });
  };

  const LoadingAnimation = () => {
    return (
      <div style={{ height: "100vh", width: "100vw", backgroundColor: "blue" }}>
        Estamos cargando los mejores estilos para ti !
      </div>
    );
  };

  return (
    <div>
      <SliderMenu albumFilter={albumFilter} setAlbumFilter={setAlbumFilter} setTagFilter={setTagFilter} fixed={true} />

      {/* 
      <Suspense fallback={<LoadingAnimation />}>
      
      <div> */}

      <div className="HeaderSpacer" style={{ height: "98px", width: "30px" }} />
      <FilterMenu albumFilter={albumFilter} tagFilter={tagFilter} setTagFilter={setTagFilter} />
      <SizeMenu props={{ columns: columns, setBreakpointCols: setBreakpointCols, setColumns: setColumns }} />
      <InfiniteScroll
        dataLength={data ? data.length : 5}
        next={fetchMore}
        hasMore={next ? true : false}
        loader={
          <div className="Loader">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }

      >
        <Masonry
          breakpointCols={breakpointCols}
          className={columns === "l" ? "my-masonry-grid my-masonry-grid-large" : "my-masonry-grid"}
          columnClassName="my-masonry-grid_column"
        >
          {data && data.length > 0 
            ? data.map((post, index) => {
              return (
                <PostScreen className="PostScreen" key={index} data={post} size={columns} index={index} />
               
              );
            })
            : <NoContentMessage customMessage="No se encontro contenido relacionado con este filtro" />}
        </Masonry>

        <div className="FeedSpacer" />
      </InfiniteScroll>

      {/* </div>
      </Suspense> */}
    </div>
  );
}

export default FeedScreen;
