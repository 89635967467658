import React, { useEffect, useState,useRef } from "react";
import ProductBox from "./ProductBox";
import NotifyMe from "./Notifyme";
import ProductModal from "./ProductModal";


export default function RecomendationMenu(props) {
  const title = props.title;
  const  postID= props.postID;
  const recommendations = props.recommendations;
  const size = Math.round(props.box_size  / 2)  - 32 - 16 
  const [product,setProduct] = useState();
  const [show ,setShow] = useState(false);
  const products = props.products ;
  const [expanded, setExpanded] = useState(false);
  const scrollRef = useRef(null);






  const handleOpenModal = (rec) => {
    console.log(rec)
    if(expanded){
      setShow(true)
      setProduct(rec)
    }
    else{
      setExpanded(true)
    }
  };


    const handleCloseModal = () => {
      setShow(false);
      setProduct(null);
      setExpanded(true);
  };


  

  useEffect(() => {
    if(product){
      console.log("El producto ha cambiado:", product);
      setShow(true)

    }
      
  }, [product]); 

  return (
    <div className="RecomendationMenuV3">
        <div className="RecomendationBarTitleV3">
          {title}
        </div>
        { recommendations?.length === 0   && <div className="RecomendationText">  Estamos preparando los mejores recomendados para este look. Mientras tanto, te presentamos lo mas compartido en Cranberry  </ div>}
        <div className="RecomendationBarProducts"  ref={scrollRef}  >
          {recommendations  && recommendations.map((rec, i) => (
            <button key={i} style={{width: size,height:size}} onClick={() => handleOpenModal(rec)}>
              <ProductBox  product={rec} i={rec.id} size={size} />
            </button>
          ))}

          {products && products.map((producto, index) => (
              <button  style={{width: size,height:size}}  key={index} onClick={() => handleOpenModal(producto)}>
              <ProductBox product={producto} i={producto.id} size={size}/>
              </button>

          ))}
        </div>
        {
          recommendations?.length === 0 && 
           <NotifyMe  postID={postID}/>
        }
      {
        show &&
        <ProductModal  productRec={product} productID={product?.id}  handleCloseModal={handleCloseModal} />
      }
    </ div>

  );
}
